<template>
  <div class="page-small">
    <h1>
      Фиды
    </h1>
    <div>
      <div class="buttons-wrap">
        <button @click="this.createNewFeed()"
                :class="{
                  'btn-pl-loader': this.isCreateHandling
                }"
        >
          Создать фид
        </button>
      </div>
      <template v-for="(group, type) in feedsByTypes ?? {}">
        <div v-if="group.list.length">
          <h2>
            {{ group.header }}
          </h2>
          <div class="info-blocks">
            <div v-for="feed in group.list" class="info-block"
                 :class="{
                  'info-block-warning-marked': 'not_created' === type
               }"
            >
              <div class="icon-buttons">
              <span @click="this.$router.push({ name: 'feeds.current', params: { feedId: feed.id } })"
                    class="icomoon-icon-pencil"
              ></span>
                <span @click="this.$router.push({ name: 'feeds.current.delete', params: { feedId: feed.id } })"
                      class="icomoon-icon-bin"
                ></span>
              </div>
              <h3 v-if="feed.name">
                {{ feed.name }}
              </h3>
              <h3 v-else>
                Без названия
              </h3>
              <div>
                <div>
                  <span class="grey-color">Позиций:</span> {{ feed.count }}
                  <br />
                  <span class="grey-color">Формат:</span> {{ feed.format?.label ?? 'не выбран' }}
                </div>
              </div>
              <div v-if="!feed.is_fully_created" class="warning-color">
                Настройка не завершена
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-if="null !== feedsByTypes && !feedsCount">
        Фиды не созданы
      </div>
    </div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";

export default {
  data() {
    return {
      isCreateHandling: false,
      feedsByTypes: null,
      feedsCount: 0
    }
  },
  methods: {
    async getFeeds() {
      const successCallback = (response) => {
        const feeds = response.data?.feeds ?? []

        this.feedsByTypes = {
          enabled: {
            header: 'Активные',
            list: []
          },
          not_created: {
            header: 'Настройка не завершена',
            list: []
          },
          disabled: {
            header: 'Отключенные',
            list: []
          }
        }
        for (let key in feeds) {
          let type = 'enabled'
          if (!feeds[key].is_fully_created) {
            type = 'not_created'
          } else if (feeds[key].is_disabled) {
            type = 'disabled'
          }

          this.feedsByTypes[type].list.push(feeds[key])
          this.feedsCount++
        }
      }

      await apiTransport({
        apiMethod: 'feeds',
        queryParams: {
          include_not_created: 1
        },
        successCallback: successCallback
      })
    },
    async createNewFeed() {
      this.isCreateHandling = true
      const successCallback = (response) => {
        const feedId = response.data?.feed_id

        if (!feedId) {
          return
        }

        this.$router.push({ name: 'feeds.current', params: { feedId: feedId } })
      }

      const finallyCallback = () => {
        this.isCreateHandling = false
      }

      await apiTransport({
        apiMethod: 'feeds/create-blank',
        httpMethod: 'post',
        successCallback: successCallback,
        finallyCallback: finallyCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getFeeds()
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>