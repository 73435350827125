<template>
  <div class="page-notifs-wrap">
    <div class="page-notifs">
      <div v-for="notif in notifications"
           :class="{
              'page-notif': true,
              'page-notif-success': 'success' === notif.type,
              'page-notif-error': 'error' === notif.type
           }"
      >
        <div class="page-notif-label">
          {{ notif.label }}
        </div>
        <div v-if="notif.description" class="page-notif-desc">
          {{ notif.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      notifications: this.$store.getters.getPageNotifications
    }
  }
}
</script>

<style scoped>
  .page-notifs-wrap {
    position: fixed;
    right: 0;
    bottom: var(--mobile-nav-height);
    padding-bottom: env(safe-area-inset-bottom);
  }
  .page-notifs {
    margin-right: var(--primary-gap);
    margin-bottom: var(--primary-gap);
    display: flex;
    flex-direction: column;
    gap: var(--primary-gap);
    overflow: hidden;
  }
  .page-notif {
    border-radius: var(--primary-br);
    padding: var(--primary-gap);
    color: #fff;
  }
  .page-notif-success {
    background: var(--success-color);
  }
  .page-notif-error {
    background: var(--error-color);
  }
  .page-notif-warning {
    background: var(--warning-color);
  }
  .page-notif-label {
    font-weight: bold;
  }
  @media (min-width: 768px) {
    .page-notifs-wrap {
      bottom: 0;
    }
  }
</style>