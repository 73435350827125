<template>
  <div class="page-small">
    <h1 v-if="!pricelist?.main_info?.name">
      Новый прайс-лист
    </h1>
    <h1 v-else>
      Прайс-лист «{{ pricelist.main_info.name }}»
    </h1>
    <div v-if="null !== pricelist" class="info-blocks">
      <div v-if="!isFullyCreated" class="error-color">
        Настройка не завершена – загрузка невозможна
      </div>
      <div class="info-block"
           :class="{
              'info-block-error-marked': !hasMainInfo
           }"
      >
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'pricelists.current.edit.main', params: { pricelistId: pricelist.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-file-text2"></span> Основная информация
        </h3>
        <div v-if="!pricelist.main_info.is_disabled">
          <span class="icomoon-icon-switch success-color"></span> Прайс-лист включен
        </div>
        <div v-if="pricelist.main_info.is_disabled">
          <span class="icomoon-icon-switch error-color"></span> Прайс-лист выключен
        </div>
        <div>
          <span class="grey-color">Поставщик:</span> {{ supplierName }}
          <br />
          <span class="grey-color">Филиал:</span> {{ branchOfficeName }}
        </div>
        <div>
          <span class="grey-color">Загружено позиций:</span> {{ pricelist.count }}
        </div>
      </div>
      <div class="info-block"
           :class="{
              'info-block-error-marked': !hasMarkupsConfigs
           }"
      >
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'pricelists.current.markups_configs.all', params: { pricelistId: pricelist.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-price-tags"></span> Наценки
        </h3>
        <div v-if="hasMarkupsConfigs">
          Установлены наценки для {{ pricelist.markups_configs_count }} групп цен
        </div>
        <div v-else class="error-color">
          Наценки не настроены
        </div>
      </div>
      <div class="info-block">
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'pricelists.current.edit.delivery', params: { pricelistId: pricelist.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-clock"></span> Поставка
        </h3>
        <div>
          <span class="grey-color">Срок поставки:</span> {{ pricelist.delivery.user_format }}
        </div>
      </div>
      <div class="info-block"
           :class="{
              'info-block-error-marked': !hasUploadFileConfig
           }"
      >
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'pricelists.current.edit.upload_file_config', params: { pricelistId: pricelist.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-table2"></span> Столбцы
        </h3>
        <div v-if="hasUploadFileConfig">
          Настроено
        </div>
        <div v-else class="error-color">
          Столбцы не настроены
        </div>
      </div>
      <div class="info-block">
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'pricelists.current.edit.filters', params: { pricelistId: pricelist.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-filter"></span> Фильтрация и автозамена
        </h3>
        <div>
          <span class="grey-color">Цены:</span> от {{ pricelist.filters.min_price }} до {{ pricelist.filters.max_price }}
        </div>
      </div>
      <div class="info-block">
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'pricelists.current.edit.currency', params: { pricelistId: pricelist.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-sphere"></span> Валюта
        </h3>
        <div v-if="pricelist.currency?.in_currency && pricelist.currency.in_currency.id !== pricelist.currency.out_currency.id">
          <span class="grey-color">Конвертация:</span> по курсу ЦБ
          <br />
          {{ pricelist.currency.in_currency.full_user_name }} → {{ pricelist.currency.out_currency.full_user_name }}
        </div>
        <div v-if="pricelist.currency?.in_currency && pricelist.currency.in_currency.id === pricelist.currency.out_currency.id">
          <span class="grey-color">Конвертация:</span> 1 к 1
        </div>
        <div v-if="pricelist.currency?.custom_currency_coef">
          <span class="grey-color">Конвертация:</span> по коэффициенту
          <br />
          Цена × {{ pricelist.currency.custom_currency_coef }}
        </div>
        <div v-if="!pricelist.currency?.in_currency && !pricelist.currency?.custom_currency_coef">
          <span class="grey-color">Конвертация:</span> не настроена
        </div>
      </div>
      <div class="info-block">
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'pricelists.current.edit.download_config', params: { pricelistId: pricelist.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-download"></span> Автозагрузка
        </h3>
        <div v-if="1 === pricelist.download_type">
          Автозагрузка с почтового ящика
        </div>
        <div v-else-if="2 === pricelist.download_type">
          Автозагрузка по ссылке
        </div>
        <div v-else-if="3 === pricelist.download_type">
          Автозагрузка с FTP-сервера
        </div>
        <div v-else>
          Автозагрузка не настроена
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";

export default {
  data() {
    const pricelistId = this.$route.params?.pricelistId ?? null

    return {
      pricelistId: pricelistId,
      pricelist: null,
      isFullyCreated: false,
      supplierName: 'не указан',
      branchOfficeName: 'не указан',
      hasMainInfo: false,
      hasMarkupsConfigs: false,
      hasUploadFileConfig: false
    }
  },
  methods: {
    async getPricelist() {
      const successCallback = (response) => {
        this.pricelist = response.data?.pricelist_summary

        this.isFullyCreated = this.pricelist.is_fully_created
        this.hasMainInfo = this.pricelist.has_main_info
        this.hasMarkupsConfigs = this.pricelist.has_markups_configs
        this.hasUploadFileConfig = this.pricelist.has_upload_file_config

        if (this.hasMainInfo) {
          this.supplierName = this.pricelist.main_info.supplier_name
          this.branchOfficeName = this.pricelist.main_info.branch_office.name
        }
      }

      await apiTransport({
        apiMethod: `pricelists/${this.pricelistId}/summary`,
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getPricelist()
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>

<style scoped>

</style>