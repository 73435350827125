<template>
  <input :id="fieldName"
         :type="field.type"
         :maxlength="field?.max_length ?? null"
         :min="field?.min ?? null"
         :max="field?.max ?? null"
         :disabled="!!field?.disabled"
         :value="value"
         @change="$emit('update:value', $event.target.value)"
  />
  <label :for="fieldName">
    {{ field.label }}
  </label>
</template>

<script>
export default {
  props: {
    fieldName: {
      type: String,
      default: ''
    },
    field: {
      type: Object,
      default: {
        label: '',
        type: 'text'
      }
    },
    value: {
      default: ''
    }
  }
}
</script>