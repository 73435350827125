<template>
  <input id="toggle_nav_checkbox" type="checkbox" :checked="isNavCollapsed" v-model="isNavCollapsed" />
  <input id="mobile_nav_toggle_checkbox" type="checkbox" v-model="isMobileNavOpened" />
  <div class="nav hidden_scroll_overflow">
    <div class="nav_header_wrap">
      <div class="nav_header">
        Панель управления
      </div>
      <label for="toggle_nav_checkbox"><span class="icomoon-icon-menu"></span></label>
    </div>
    <nav class="hidden_scroll_outer">
      <ul class="hidden_scroll_inner">
        <template v-for="button in buttons">
          <li v-if="false !== button?.hidden"
              :title="button.text"
          >
            <input :id="button.id + '_nav_btn'" type="checkbox" v-model="button.is_opened" />
            <component :is="button?.route_name ? 'router-link' : 'label'"
                       :to="button?.route_name ? { name: button.route_name } : null"
                       :for="button?.route_name ? null : button.id + '_nav_btn'"
                       :exact-active-class="button?.route_name ? 'exact-active' : null"
                       :class="{
                       'exact-active': !!button.is_active
                     }"
            >
              <span :class="'icomoon-icon-' + button.icon"></span>
              <span class="button_text">
              {{ button.text }}
            </span>
            </component>
            <ul v-if="button?.children">
              <template v-for="childButton in button.children">
                <li v-if="false !== childButton?.hidden"
                    :title="button.text"
                >
                  <router-link :to="{ name: childButton.route_name }"
                               :exact-active-class="'exact-active'"
                               :active-class="'active'"
                               :event="button?.disabled ? '' : 'click'"
                  >
                    <span :class="'icomoon-icon-' + childButton.icon"></span><span class="button_text">
                  {{ childButton.text }}
                </span>
                  </router-link>
                </li>
              </template>
            </ul>
          </li>
        </template>
      </ul>
    </nav>
    <div class="nav-footer-wrap">
      <Switcher :is-enabled="isDarkThemeEnabled"
                :label="'Тема'"
                v-model:isEnabled="isDarkThemeEnabled"
      ></Switcher>
    </div>
  </div>
</template>

<script>
import Switcher from "@/components/UI/Form/Switcher.vue";
import store from "@/store/store";
import getRoles from "@/pages/Platform/Users/Enums/getRoles";

export default {
  components: {Switcher},
  data() {
    const isButtonActive = (id) => {
      return id === this.$route.matched[1]?.name
    }

    const roles = getRoles()

    return {
      isDarkThemeEnabled: this.$store.getters.isDarkThemeEnabled,
      isNavCollapsed: this.$store.getters.isNavCollapsed,
      isMobileNavOpened: false,
      buttons: [
        {
          id: 'summary',
          text: 'Сводка',
          route_name: 'summary',
          icon: 'display'
        },
        {
          id: 'orders',
          text: 'Заказы',
          route_name: 'orders.active',
          icon: 'files-empty',
          hidden: this.$store.getters.hasAuthUserRole([ roles.SUPER_ADMIN, roles.HANDLE_ORDERS ])
        },
        {
          id: 'goods',
          text: 'Товары',
          icon: 'stack',
          is_active: isButtonActive('goods'),
          is_opened: isButtonActive('goods'),
          children: [
            {
              text: 'Прайс-листы',
              route_name: 'pricelists.all',
              icon: 'files-empty',
              hidden: this.$store.getters.hasAuthUserRole([ roles.SUPER_ADMIN, roles.EDIT_PRICELISTS ])
            },
            {
              text: 'Фиды',
              route_name: 'feeds.all',
              icon: 'price-tags',
              hidden: this.$store.getters.hasAuthUserRole([ roles.SUPER_ADMIN, roles.EDIT_FEEDS ])
            },
            {
              text: 'Группы цен',
              route_name: 'pricesgroups.all',
              icon: 'ticket'
            }
          ]
        },
        {
          id: 'settings',
          text: 'Настройки',
          icon: 'equalizer',
          hidden: this.$store.getters.hasAuthUserRole([ roles.SUPER_ADMIN, roles.EDIT_SETTINGS ]),
          is_active: isButtonActive('settings'),
          is_opened: isButtonActive('settings'),
          children: [
            {
              text: 'Маркетплейсы',
              route_name: 'marketplaces.all',
              icon: 'rocket'
            },
            {
              text: 'Филиалы',
              route_name: 'branchoffices.all',
              icon: 'tree',
              hidden: this.$store.getters.hasAuthUserRole([ roles.SUPER_ADMIN, roles.EDIT_BRANCH_OFFICES_COMPANIES ])
            },
            {
              text: 'Компании',
              route_name: 'companies.all',
              icon: 'briefcase',
              hidden: this.$store.getters.hasAuthUserRole([ roles.SUPER_ADMIN, roles.EDIT_BRANCH_OFFICES_COMPANIES ])
            },
            {
              text: 'Настройки магазина',
              route_name: 'shop.settings',
              icon: 'cogs'
            },
            {
              text: 'Почтовые профили',
              route_name: 'emailprofiles.all',
              icon: 'mail2'
            },
            {
              text: 'Пользователи',
              route_name: 'users.all',
              icon: 'user-tie',
              hidden: this.$store.getters.hasAuthUserRole([ roles.SUPER_ADMIN, roles.EDIT_ADMINS ])
            },
            {
              text: 'Настройки аккаунта',
              route_name: 'account.settings',
              icon: 'lock'
            }
          ]
        },
        {
          id: 'billing',
          text: 'Платежная информация',
          route_name: 'billing',
          icon: 'credit-card'
        },
        {
          id: 'exit',
          text: 'Выйти',
          route_name: 'logout',
          icon: 'exit'
        }
      ]
    }
  },
  methods: {
    enableDarkTheme() {
      document.body.classList.add('dark-theme')
    },
    disableDarkTheme() {
      document.body.classList.remove('dark-theme')
    }
  },
  watch: {
    '$route' (to) {
      const secondLevelRouteName = to.matched[1]?.name

      for (let key in this.buttons) {
        let button = this.buttons[key]

        if (undefined === button?.is_active) {
          continue
        }

        button.is_active = button?.id === secondLevelRouteName;
      }

      this.isMobileNavOpened = false // close nav on mobile
    },
    isDarkThemeEnabled (newValue) {
      if (newValue) {
        this.enableDarkTheme()
        store.commit('enableDarkTheme')
      } else {
        this.disableDarkTheme()
        store.commit('disableDarkTheme')
      }
    },
    isNavCollapsed (newValue) {
      this.isNavCollapsed = newValue

      if (this.isNavCollapsed) {
        store.commit('collapseNav')
      } else {
        store.commit('expandNav')
      }
    }
  },
  mounted() {
    this.isDarkThemeEnabled ? this.enableDarkTheme() : this.disableDarkTheme()
  }
}
</script>

<style scoped>
.nav {
  max-width: 250px;
  background: var(--main-nav-bg);
  flex-grow: 1;
  display: none;
}
#toggle_nav_checkbox,
#mobile_nav_toggle_checkbox,
.nav ul li input {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
  overflow: hidden;
}
#mobile_nav_toggle_checkbox:checked ~ .nav {
  display: block;
}
nav > ul {
  margin: 0;
  padding: 36px 0 0;
}
.nav,
.nav ul a {
  color: #fff;
}
.nav ul a,
.nav ul > li > label,
.nav_header_wrap {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.nav ul a,
.nav ul > li > label,
.nav_header_wrap > *,
.nav-footer-wrap {
  padding: 0 10px;
}
.nav_header_wrap,
.nav-footer-wrap {
  height: 36px;
  position: absolute;
  width: 100%;
  top: 0;
  box-shadow: 0 -1px 5px 0 #000;
  z-index: 1;
  background: var(--main-nav-bg);
}
.nav-footer-wrap {
  top: auto;
  bottom: 0;
  height: 42px;
  box-shadow: 0 1px 5px 0 #000;
  display: flex;
  align-items: center;
}
.nav_header_wrap label {
  margin-left: auto;
  cursor: pointer;
  opacity: .8;
  display: flex;
  height: 100%;
  align-items: center;
}
.nav_header_wrap label:hover {
  opacity: 1;
}
.nav ul a,
.nav ul > li > label {
  height: 40px;
  text-decoration: none;
  transition: all 100ms ease;
}
.nav nav > ul > li > a.exact-active,
.nav nav > ul > li > label.exact-active {
  border-left: 3px solid #359a20;
}
.nav nav > ul > li > a.exact-active,
.nav nav > ul > li > input:checked ~ label,
.nav nav > ul > li > label.exact-active,
.nav nav > ul > li > a.active,
.nav nav > ul > li > label:hover,
.nav nav > ul > li > a:hover,
.nav nav > ul > li.nested_nav_shown > a {
  background: var(--main-nav-active-btn-bg);
}
.nav ul li.nested_nav_shown > a::before
.nav ul li.nested_nav_shown > a::after {
  content: '';
  height: 10px;
  width: 1px;
}
.nav ul ul {
  list-style: none;
  display: none;
  padding-left: 10px;
  background: var(--main-nav-nested-bg);
}
.nav ul li input:checked ~ ul,
.nav a.active ~ ul {
  display: block;
}
.nav ul li.nested_nav_shown > ul {
  display: block;
}
.nav ul ul ul {
  padding-left: 7px;
}
.nav ul ul a {
  color: #7e8082;
  height: 30px;
  text-decoration: none;
  padding: 0 7px;
  font-size: 13px;
}
.nav ul ul a.exact-active,
.nav ul ul a.active,
.nav ul ul a:hover {
  color: #fff;
}
.nav ul ul a.exact-active,
.nav ul ul a.active {
  font-size: 13.5px;
}
.nav a.has_nested_nav::after {
  font-family: 'Glyphicons Halflings' !important;
  content: "\e079";
  font-size: 11px;
  margin-left: auto;
  color: #717171;
  transition: all .1s ease;
}
.nav ul ul a.has_nested_nav::after {
  font-size: 9px;
}
.nav li:not(.nested_nav_shown) > a.has_nested_nav::after {
  transform: rotate(0deg);
}
.nav li.nested_nav_shown > a.has_nested_nav::after {
  color: #fff;
  transform: rotate(-90deg);
}
@media (max-width: 767px) {
  .nav {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 50px;
    max-width: none;
    z-index: 9996;
  }
  .nav label {
    display: none;
  }
  nav > ul > li:last-child {
    padding-bottom: 50px; /* fix for parent padding (parent has small height) */
  }
}
@media (min-width: 500px) {
  .nav ul a,
  .nav ul > li > label,
  .nav_header_wrap > *,
  .nav-footer-wrap {
    padding: 0 15px;
  }
  .nav nav a,
  .nav ul > li > label {
    height: 50px;
  }
}
@media (min-width: 768px) {
  .nav {
    display: block;
  }
  nav > ul::after {
    content: '';
    display: block;
    height: 46px;
  }
}
#toggle_nav_checkbox:checked ~ .nav {
  max-width: 50px;
}
#toggle_nav_checkbox:checked ~ .nav .nav_header,
#toggle_nav_checkbox:checked ~ .nav .nav-footer-wrap {
  display: none;
}
#toggle_nav_checkbox:checked ~ .nav nav > ul > li > a {
  justify-content: center;
}
#toggle_nav_checkbox:checked ~ .nav .nav_header_wrap label {
  margin: 0 auto;
}
#toggle_nav_checkbox:checked ~ .nav .nav_header_wrap a,
#toggle_nav_checkbox:checked ~ .nav .button_text {
  display: none;
}
#toggle_nav_checkbox:checked ~ .nav ul ul {
  padding-left: 0;
}
#toggle_nav_checkbox:checked ~ .nav ul ul a {
  justify-content: center;
}
</style>