import store from "@/store/store";

export default function(response, callback) {
    if (response.data?.token
        && response.data?.refresh_token
        && response.data?.data?.user?.id
    ) {
        const newAuthState = {
            userId: response.data.data.user.id,
            roles: response.data.data.user.roles,
            token: response.data.token,
            refresh_token: response.data.refresh_token,
            isLogged: true,
            isSubscribed: false
        }
        store.commit('newAuth', newAuthState)

        if (callback) {
            callback()
        }
    }
}