<template>
  <div class="page-small">
    <h1>
      Прайс-листы
    </h1>
    <div>
      <div class="buttons-wrap">
        <button @click="this.createNewPricelist()"
                :class="{
                  'btn-pl-loader': this.isCreateHandling
                }"
        >
          Создать прайс-лист
        </button>
      </div>
      <template v-for="(group, type) in pricelistsByTypes ?? {}">
        <div v-if="group.list.length">
          <h2>
            {{ group.header }}
          </h2>
          <div class="info-blocks">
            <div v-for="pricelist in group.list" class="info-block"
                 :class="{
                  'info-block-warning-marked': 'not_created' === type
               }"
            >
              <div class="icon-buttons">
              <span @click="this.$router.push({ name: 'pricelists.current', params: { pricelistId: pricelist.id } })"
                    class="icomoon-icon-pencil"
              ></span>
                <span @click="this.$router.push({ name: 'pricelists.current.delete', params: { pricelistId: pricelist.id } })"
                      class="icomoon-icon-bin"
                ></span>
              </div>
              <h3 v-if="pricelist.name">
                {{ pricelist.name }}
              </h3>
              <h3 v-else>
                Без названия
              </h3>
              <div>
                <div>
                  <span class="grey-color">Поставщик:</span> {{ '' === pricelist.supplier_name ? 'не указан' : pricelist.supplier_name }}
                </div>
                <div>
                  <span class="grey-color">Срок:</span> {{ pricelist.delivery.user_format }}
                </div>
                <div>
                  <span class="grey-color">Позиций:</span> {{ pricelist.count }}
                </div>
              </div>
              <div v-if="!pricelist.is_fully_created" class="warning-color">
                Настройка не завершена
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-if="null !== pricelistsByTypes && !pricelistsCount">
        Прайс-листы не созданы
      </div>
    </div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";

export default {
  data() {
    return {
      isCreateHandling: false,
      pricelistsByTypes: null,
      pricelistsCount: 0
    }
  },
  methods: {
    async getPricelists() {
      const successCallback = (response) => {
        const pricelists = response.data?.pricelists ?? []

        this.pricelistsByTypes = {
          enabled: {
            header: 'Активные',
            list: []
          },
          not_created: {
            header: 'Настройка не завершена',
            list: []
          },
          disabled: {
            header: 'Отключенные',
            list: []
          }
        }
        for (let key in pricelists) {
          let type = 'enabled'
          if (!pricelists[key].is_fully_created) {
            type = 'not_created'
          } else if (pricelists[key].is_disabled) {
            type = 'disabled'
          }

          this.pricelistsByTypes[type].list.push(pricelists[key])
          this.pricelistsCount++
        }
      }

      await apiTransport({
        apiMethod: 'pricelists',
        queryParams: {
          include_not_created: 1
        },
        successCallback: successCallback
      })
    },
    async createNewPricelist() {
      this.isCreateHandling = true
      const successCallback = (response) => {
        const pricelistId = response.data?.pricelist_id

        if (!pricelistId) {
          return
        }

        this.$router.push({ name: 'pricelists.current', params: { pricelistId: pricelistId } })
      }

      const finallyCallback = () => {
        this.isCreateHandling = false
      }

      await apiTransport({
        apiMethod: 'pricelists/create-blank',
        httpMethod: 'post',
        successCallback: successCallback,
        finallyCallback: finallyCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getPricelists()
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>