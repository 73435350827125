<template>
  <div class="page-form">
    <h1 v-if="isCreate">
      Новый филиал
    </h1>
    <h1 v-else>
      Редактирование филиала
    </h1>
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :submit-button-text="submitButtonText"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    ></Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";

export default {
  components: {Form},
  data() {
    const branchOfficeId = this.$route.params?.branchOfficeId ?? null
    const isCreate = null === branchOfficeId
    const successCallback = (response) => {
      this.$router.push({ name: 'branchoffices.all' })
    }
    const cancelCallback = (response) => {
      this.$router.push({ name: 'branchoffices.all' })
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('company_id', 'company_id', {
          label: 'Компания',
          type: 'select',
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ],
          required: true
        })
        .addFieldSetField('name', 'name', {
          label: 'Название филиала',
          type: 'text',
          required: true
        })

    return {
      isCreate: isCreate,
      submitButtonText: isCreate ? 'Создать' : 'Сохранить',
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      branchOfficeId: branchOfficeId,
      apiMethod: isCreate ? 'branch-offices/create' : 'branch-offices/' + branchOfficeId + '/update',
    }
  },
  methods: {
    async getBranchOffice() {
      const successCallback = (response) => {
        if (response.data?.branch_office) {
          for (let fieldName in response.data.branch_office) {
            if (this.formBuilder.hasField(fieldName)) {
              this.formBuilder.updateFieldParam(fieldName, 'value', response.data.branch_office[fieldName])
            }
          }

          // Map company id
          this.formBuilder.updateFieldParam(
              'company_id',
              'value',
              response.data.branch_office.company?.id
          )
        }
      }

      await apiTransport({
        apiMethod: 'branch-offices/' + this.branchOfficeId,
        successCallback: successCallback
      })
    },
    async getCompanies() {
      const successCallback = (response) => {
        if (response.data?.companies) {
          // Company not required – add empty option first
          this.formBuilder.addSelectOption('company_id', {
            value: null,
            text: 'Не выбрана'
          })

          for (let key in response.data.companies) {
            let company = response.data.companies[key]
            let companyName = company?.company_user_type + ' ' + company?.company_name

            this.formBuilder.addSelectOption('company_id', {
              value: company?.id,
              text: companyName
            })
          }
        }
      }

      await apiTransport({
        apiMethod: 'companies',
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    if (!this.isCreate) {
      this.$emit('startLoading')
    }
  },
  mounted() {
    // Fetch companies first important for mapping
    this.getCompanies()
        .then(async () => {
          if (!this.isCreate) {
            await this.getBranchOffice()
          }
        })
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>