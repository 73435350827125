<template>
  <div class="form-fields-line">
    <div v-for="field in fields"
         class="form-field-of-line-wrap"
         :class="{
           'form-field-of-line-wrap-required': !!field?.required,
           'form-field-wrap-filled': field?.value !== '' && field?.value !== undefined,
         }"
    >
      <TextField v-if="'text' === field.type || 'number' === field.type"
                 :fieldName="field.renderFieldName"
                 :field="field"
                 v-model:value="field.value"
      ></TextField>

      <SelectList v-if="'select' === field.type"
                  :id="field.renderFieldName"
                  :field="field"
                  v-model:value="field.value"
      ></SelectList>
    </div>
  </div>
</template>

<script>
import TextField from "@/components/UI/Form/TextField.vue";
import SelectList from "@/components/UI/Form/SelectList.vue";

export default {
  components: {SelectList, TextField},
  props: {
    fields: {
      type: Array,
      default: []
    }
  }
}
</script>

<style scoped>
.form-fields-line {
  display: flex;
  gap: var(--primary-gap);
}
.form-field-of-line-wrap {
  position: relative;
  flex-grow: 1;
  display: flex;
}
</style>