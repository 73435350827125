<template>
  <div class="page-form">
    <h1 v-if="isCreate">
      Новый пользователь
    </h1>
    <h1 v-else>
      Редактирование пользователя
    </h1>
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :submit-button-text="submitButtonText"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    ></Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";
import getRoles from "@/pages/Platform/Users/Enums/getRoles";

export default {
  components: {Form},
  data() {
    const userId = this.$route.params?.userId ?? null
    const isCreate = null === userId
    const successCallback = (response) => {
      this.$router.push({ name: 'users.all' })
    }
    const cancelCallback = (response) => {
      this.$router.push({ name: 'users.all' })
    }

    const settingsNestedIsHiddenCallback = () => {
      return this.isEditSettingsRoleDisabled()
    }

    const isSuperAdminEnabledCallback = () => {
      return this.isSuperAdminEnabled()
    }

    const roles = getRoles()

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('email', 'email', {
          label: 'E-mail',
          type: 'text',
          required: true
        })
        .addFieldSetField('name', 'name', {
          label: 'Имя',
          type: 'text',
          required: true
        })
        .addFieldSetField('last_name', 'last_name', {
          label: 'Фамилия',
          type: 'text',
          required: true
        })
        .addFieldSetField('middle_name', 'middle_name', {
          label: 'Отчество',
          type: 'text',
          required: true
        })
        .addFieldSetField('roles_label', 'roles_label', {
          label: 'Права пользователя',
          type: 'label',
          required: true
        })
        .addFieldSetField('roles', 'super_admin_role', {
          label: 'Супер администратор',
          description: 'Пользователю доступны все права и филиалы',
          type: 'switcher',
          listFieldName: 'roles',
          value: 'super_admin_role',
          isHidden: () => {
            return !this.$store.getters.hasAuthUserRole([ roles.SUPER_ADMIN ])
          }
        })
        .addFieldSetField('roles', 'handle_orders_role', {
          label: 'Обработка заказов',
          type: 'switcher',
          listFieldName: 'roles',
          value: 'handle_orders_role',
          isDisabledEnabled: isSuperAdminEnabledCallback
        })
        .addFieldSetField('roles', 'upload_pricelists_role', {
          label: 'Загрузка прайс-листов',
          type: 'switcher',
          listFieldName: 'roles',
          value: 'upload_pricelists_role',
          isDisabledEnabled: isSuperAdminEnabledCallback
        })
        .addFieldSetField('roles', 'edit_pricelists_role', {
          label: 'Редактирование прайс-листов',
          type: 'switcher',
          listFieldName: 'roles',
          value: 'edit_pricelists_role',
          isDisabledEnabled: isSuperAdminEnabledCallback
        })
        .addFieldSetField('roles', 'edit_feeds_role', {
          label: 'Редактирование фидов',
          type: 'switcher',
          listFieldName: 'roles',
          value: 'edit_feeds_role',
          isDisabledEnabled: isSuperAdminEnabledCallback
        })
        .addFieldSetField('roles', 'edit_settings_role', {
          label: 'Редактирование настроек',
          type: 'switcher',
          listFieldName: 'roles',
          value: 'edit_settings_role',
          isDisabledEnabled: isSuperAdminEnabledCallback
        })
        .addFieldSetField('roles', 'edit_branch_offices_companies_role', {
          label: 'Редактирование филиалов и компаний',
          type: 'switcher',
          listFieldName: 'roles',
          value: 'edit_branch_offices_companies_role',
          isHidden: settingsNestedIsHiddenCallback,
          isDisabledEnabled: isSuperAdminEnabledCallback
        })
        .addFieldSetField('roles', 'edit_admins_role', {
          label: 'Редактирование пользователей',
          type: 'switcher',
          listFieldName: 'roles',
          value: 'edit_admins_role',
          isHidden: settingsNestedIsHiddenCallback,
          isDisabledEnabled: isSuperAdminEnabledCallback
        })
        .addFieldSetField('branch_offices_label', 'branch_offices_label', {
          label: 'Филиалы, доступные пользователю',
          type: 'label',
          required: true
        })

    return {
      isCreate: isCreate,
      submitButtonText: isCreate ? 'Создать' : 'Сохранить',
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      userId: userId,
      apiMethod: isCreate ? 'users/create' : `users/${userId}/update`,
      isSuperAdminEnabledCallback: isSuperAdminEnabledCallback
    }
  },
  methods: {
    isEditSettingsRoleDisabled() {
      return !this.formBuilder.getField('edit_settings_role').isEnabled
    },
    isSuperAdminEnabled() {
      return this.formBuilder.getField('super_admin_role').isEnabled
    },
    async getUser() {
      const successCallback = (response) => {
        if (response.data?.user) {
          for (let fieldName in response.data.user) {
            if (this.formBuilder.hasField(fieldName)) {
              this.formBuilder.updateFieldParam(fieldName, 'value', response.data.user[fieldName])
            }
          }

          // Map roles
          for (let roleKey in response.data.user.roles) {
            let roleFieldName = response.data.user.roles[roleKey]

            if (this.formBuilder.hasField(roleFieldName)) {
              this.formBuilder.updateFieldParam(roleFieldName, 'isEnabled', true)
            }
          }

          // Map branch offices
          for (let branchOfficeKey in response.data.user.branch_offices) {
            let branchOfficeFieldName = 'branch_office_' + response.data.user.branch_offices[branchOfficeKey].id

            if (this.formBuilder.hasField(branchOfficeFieldName)) {
              this.formBuilder.updateFieldParam(branchOfficeFieldName, 'isEnabled', true)
            }
          }
        }
      }

      await apiTransport({
        apiMethod: `users/${this.userId}`,
        successCallback: successCallback
      })
    },
    async getBranchOffices() {
      const successCallback = (response) => {
        if (response.data?.branch_offices) {
          for (let key in response.data.branch_offices) {
            let branchOffice = response.data.branch_offices[key]
            let branchOfficeFieldName = `branch_office_${branchOffice.id}`
            let companyFullName = null
            if (branchOffice?.company) {
              companyFullName = branchOffice.company?.company_user_type + ' ' + branchOffice.company?.company_name
            }

            this.formBuilder.addFieldSetField('branch_offices', branchOfficeFieldName, {
              label: branchOffice?.name,
              description: companyFullName,
              type: 'switcher',
              listFieldName: 'branch_offices',
              mapErrorsFieldName: 'branch_offices_label',
              value: branchOffice.id,
              isDisabledEnabled: this.isSuperAdminEnabledCallback
            })
          }
        }
      }

      await apiTransport({
        apiMethod: 'branch-offices',
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  async mounted() {
    // Fetch branch offices first important for mapping
    await this.getBranchOffices()
        .then(async () => {
          if (!this.isCreate) {
            await this.getUser()
          }
        })
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>