<template>
  <div class="page-form">
    <h1>
      Удаление компании
    </h1>
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :submit-button-text="submitButtonText"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    >
      <template v-slot:delete_company_info>
        <div>
          Вы уверены, что хотите удалить компанию <b>{{ fullCompanyName }}</b>?
        </div>
        <div>
          Все данные, которые были связаны этой компанией, больше не будут иметь привязку к компании!
        </div>
        <div>
          <b>Это действие нельзя будет отменить!</b>
        </div>
      </template>
    </Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import Form from "@/components/UI/Form/Form.vue";
import FormBuilder from "@/components/UI/Form/FormBuilder";

export default {
  components: {Form},
  data() {
    const companyId = parseInt(this.$route.params.companyId)
    const goToCompaniesList = () => {
      this.$router.push({ name: 'companies.all' })
    }
    const successCallback = (response) => {
      goToCompaniesList()
    }
    const cancelCallback = (response) => {
      goToCompaniesList()
    }

    const formBuilder = new FormBuilder()

    formBuilder
        .addFieldSetField('delete_company_info', 'delete_company_info', {
          slot: 'delete_company_info',
          type: 'infoblock'
        })

    return {
      submitButtonText: 'Да, удалить',
      formBuilder: formBuilder,
      onSuccess: successCallback,
      onCancel: cancelCallback,
      companyId: companyId,
      apiMethod: `companies/${companyId}/delete`,
      fullCompanyName: ''
    }
  },
  methods: {
    async getCompany() {
      const successCallback = (response) => {
        if (response.data?.company) {
          const company = response.data.company

          this.fullCompanyName = company?.company_user_type + ' ' + company?.company_name
        }
      }

      await apiTransport({
        apiMethod: `companies/${this.companyId}`,
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getCompany()
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>