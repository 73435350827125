<template>
  <div>
    <h1>
      Страница не найдена
    </h1>
    <div style="font-size: 72px; font-weight: bold;">
      404
    </div>
  </div>
</template>

<script>
export default {

}
</script>