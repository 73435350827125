<template>
  <div class="page-small">
    <h1>
      Наценки
    </h1>
    <div class="info-blocks">
      <div class="buttons-wrap">
        <button @click="this.$router.push({ name: 'pricelists.current.markups_configs.add' })">
          Добавить наценки
        </button>
      </div>
      <div v-for="markupsConfig in markupsConfigs ?? []" class="info-block">
        <div class="icon-buttons">
          <span @click="this.$router.push({name: 'pricelists.current.markups_configs.current.edit', params: { markupsConfigId: markupsConfig.id } })"
                class="icomoon-icon-pencil"
          ></span>
          <span @click="this.$router.push({ name: 'pricelists.current.markups_configs.current.delete', params: { markupsConfigId: markupsConfig.id } })"
              class="icomoon-icon-bin"
          ></span>
        </div>
        <h3>
          Наценки для группы «{{ markupsConfig.prices_group.name }}»
        </h3>
        <div>
          <span class="grey-color">Наценок по диапазонам:</span> {{ markupsConfig.markups.diapasons_markups.length }}
          <br />
          <span class="grey-color">Корректировок на бренды:</span> {{ markupsConfig.markups.brands_markups.length }}
        </div>
      </div>
      <div v-if="null !== markupsConfigs && !markupsConfigs.length">
        Наценки не настроены
      </div>
    </div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";

export default {
  data() {
    return {
      pricelistId: this.$route.params.pricelistId,
      markupsConfigs: null
    }
  },
  methods: {
    async getMarkupsConfigs() {
      const successCallback = (response) => {
        this.markupsConfigs = response.data?.markups_configs ?? []
      }

      await apiTransport({
        apiMethod: `pricelists/${this.pricelistId}/markups`,
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getMarkupsConfigs()
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>