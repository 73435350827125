<template>
  <div>
    <h1>
      Сводка
    </h1>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>