export default function auth({ next, store }) {
    if (!store.getters.auth.isLogged) {
        return next({
            name: 'sign_in',
            query: {
                returnUrl: window.location.pathname + window.location.search + window.location.hash
            }
        })
    }

    return next()
}
