<template>
  <div class="page-small">
    <h1>
      Почтовые профили
    </h1>
    <div class="info-blocks">
      <div class="buttons-wrap">
        <button @click="this.$router.push({ name: 'emailprofiles.add' })">
          Добавить профиль
        </button>
      </div>
      <div v-for="emailProfile in emailProfiles ?? []" class="info-block">
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'emailprofiles.current.edit', params: { emailProfileId: emailProfile.id } })"
                class="icomoon-icon-pencil"
          ></span>
          <span @click="this.$router.push({ name: 'emailprofiles.current.delete', params: { emailProfileId: emailProfile.id } })"
                class="icomoon-icon-bin"
          ></span>
        </div>
        <h3>
          {{ emailProfile.name }}
        </h3>
        <div>
          {{ emailProfile.email }}
        </div>
      </div>
      <div v-if="null !== emailProfiles && !emailProfiles.length">
        Почтовые профили не добавлены
      </div>
    </div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";

export default {
  data() {
    return {
      emailProfiles: null
    }
  },
  methods: {
    async getEmailProfiles() {
      const successCallback = (response) => {
        this.emailProfiles = response.data?.email_profiles ?? []
      }

      await apiTransport({
        apiMethod: 'email-profiles',
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getEmailProfiles()
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>