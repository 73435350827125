<template>
  <div class="page-form">
    <h1>
      Основные настройки
    </h1>
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    ></Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";

export default {
  components: {Form},
  data() {
    const feedId = this.$route.params.feedId

    const goToFeedSummary = () => {
      this.$router.push({ name: 'feeds.current', params: { feedId: feedId } })
    }

    const successCallback = (response) => {
      goToFeedSummary()
    }
    const cancelCallback = (response) => {
      goToFeedSummary()
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('branch_office_id', 'branch_office_id', {
          label: 'Филиал',
          type: 'select',
          required: true,
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ],
          isDisabled: () => {
            return null !== this.branchOfficeId
          }
        })
        .addFieldSetField('format_id', 'format_id', {
          label: 'Формат',
          type: 'select',
          required: true,
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ],
          isDisabled: () => {
            return null !== this.formatId
          }
        })
        .addFieldSetField('is_enabled', 'is_enabled', {
          label: 'Фид включен',
          description: 'Генерируется, доступен к скачиванию',
          type: 'switcher'
        })
        .addFieldSetField('name', 'name', {
          label: 'Название фида',
          type: 'text',
          required: true
        })

    return {
      formatId: null,
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      apiMethod: `feeds/${feedId}/main-info/update`,
      feedId: feedId,
      branchOfficeId: null
    }
  },
  methods: {
    isBranchOfficeSet() {
      return null !== this.branchOfficeId
    },
    async getFeedMainInfo() {
      const successCallback = (response) => {
        if (response.data?.feed_main_info) {
          const mainInfo = response.data.feed_main_info

          this.formBuilder.updateFieldParam('name', 'value', mainInfo?.name ?? '')
          this.formBuilder.updateFieldParam('is_enabled', 'isEnabled', !mainInfo?.is_disabled)

          if (mainInfo?.format) {
            this.formatId = mainInfo.format.id
            this.formBuilder.updateFieldParam('format_id', 'value', this.formatId)
          }

          if (mainInfo?.branch_office) {
            this.branchOfficeId = mainInfo.branch_office.id
            this.formBuilder.updateFieldParam('branch_office_id', 'value', this.branchOfficeId)
          }
        }
      }

      await apiTransport({
        apiMethod: `feeds/${this.feedId}/main-info`,
        successCallback: successCallback
      })
    },
    async getBranchOffices() {
      const successCallback = (response) => {
        if (response.data?.branch_offices) {
          for (let key in response.data.branch_offices) {
            let branchOffice = response.data.branch_offices[key]

            this.formBuilder.addSelectOption('branch_office_id', {
              value: branchOffice?.id,
              text: branchOffice?.name
            })
          }
        }
      }

      await apiTransport({
        apiMethod: 'branch-offices',
        successCallback: successCallback
      })
    },
    async getFeedFormats() {
      const successCallback = (response) => {
        if (response.data?.formats) {
          for (let key in response.data.formats) {
            let format = response.data.formats[key]

            this.formBuilder.addSelectOption('format_id', {
              value: format.id,
              text: format.label
            })
          }
        }
      }

      await apiTransport({
        apiMethod: 'feeds/formats',
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    // Fetch feed formsts and branch offices first important for mapping
    Promise.all([this.getFeedFormats(), this.getBranchOffices()])
      .then(async () => {
        await this.getFeedMainInfo()
      })
      .then(() => {
        this.$emit('finishLoading')
      })
  }
}
</script>