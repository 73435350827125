<template>
  <div class="page-small">
    <h1 v-if="!feed?.main_info?.name">
      Новый фид
    </h1>
    <h1 v-else>
      Фид «{{ feed.main_info.name }}»
    </h1>
    <div v-if="null !== feed" class="info-blocks">
      <div v-if="!isFullyCreated" class="error-color">
        Настройка не завершена – генерация невозможна
      </div>
      <div class="info-block"
           :class="{
              'info-block-error-marked': !hasMainInfo
           }"
      >
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'feeds.current.edit.main', params: { feedId: feed.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-file-text2"></span> Основная информация
        </h3>
        <div v-if="!feed.main_info.is_disabled">
          <span class="icomoon-icon-switch success-color"></span> Фид включен
        </div>
        <div v-if="feed.main_info.is_disabled">
          <span class="icomoon-icon-switch error-color"></span> Фид выключен
        </div>
        <div>
          {{ formatLabel }}
          <br />
          <span class="grey-color">Филиал:</span> {{ feed.main_info.branch_office?.name ?? 'не указан' }}
        </div>
        <div>
          <span class="grey-color">Сгенерировано позиций:</span> {{ feed.count }}
        </div>
      </div>

      <div class="info-block"
           :class="{
              'info-block-error-marked': !hasOffersConfig
           }"
      >
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'feeds.current.edit.offers_config', params: { feedId: feed.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-cogs"></span> Настройки предложений
        </h3>
        <div v-if="hasOffersConfig">
          <span class="grey-color">Группа цен:</span> TODO <!--{{ feed.prices_group.name }}-->
          <br />
          <span class="grey-color">Цены:</span> от {{ feed.offers_config.min_price }} до {{ feed.offers_config.max_price }}
          <br />
          <span class="grey-color">Количество предложений:</span> {{ feed.offers_config.max_count ?? 'не ограничено' }}
        </div>
        <div v-else class="error-color">
          Не настроено
        </div>
      </div>

      <div class="info-block"
           :class="{
              'info-block-error-marked': !hasPricelistsConfig
           }"
      >
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'feeds.current.edit.pricelists', params: { feedId: feed.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-files-empty"></span> Прайс-листы
        </h3>
        <div v-if="hasPricelistsConfig">
          {{ feed.pricelists_count }} прайс-лист(а/ов)
        </div>
        <div v-else class="error-color">
          Прайс-листы не выбраны
        </div>
      </div>

      <div class="info-block"
           :class="{
              'info-block-error-marked': !hasScheduleConfig
           }"
      >
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'feeds.current.edit.schedule', params: { feedId: feed.id } })"
                class="icomoon-icon-pencil"
          ></span>
        </div>
        <h3>
          <span class="icomoon-icon-calendar"></span> Расписание генерации
        </h3>
        <div v-if="hasScheduleConfig">
          {{ feed.per_week_generations_count }} раз(а) в неделю
        </div>
        <div v-else class="error-color">
          Расписание не настроено
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";

export default {
  data() {
    const feedId = this.$route.params?.feedId ?? null

    return {
      feedId: feedId,
      feed: null,
      isFullyCreated: false,
      formatLabel: 'Формат фида не выбран',
      hasMainInfo: false,
      hasOffersConfig: false,
      hasScheduleConfig: false,
      hasPricelistsConfig: false,
    }
  },
  methods: {
    async getFeed() {
      const successCallback = (response) => {
        this.feed = response.data.feed_summary

        this.isFullyCreated = this.feed.is_fully_created
        this.hasMainInfo = this.feed.has_main_info
        this.hasOffersConfig = this.feed.has_content_config
        this.hasScheduleConfig = this.feed.has_schedule_config
        this.hasPricelistsConfig = this.feed.has_pricelists_config

        if (this.hasMainInfo) {
          this.formatLabel = this.feed.main_info.format.label
        }
      }

      await apiTransport({
        apiMethod: `feeds/${this.feedId}/summary`,
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getFeed()
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>

<style scoped>

</style>