import { createStore } from "vuex";

const defaultUserState = {
    userId: null,
    roles: [],
    token: null,
    refresh_token: null,
    isLogged: false,
    isSubscribed: false
}

const localStorageUserState = JSON.parse(window.localStorage.getItem('user'))

let userState = defaultUserState

if (localStorageUserState?.userId
    && localStorageUserState?.token
    && localStorageUserState?.refresh_token
) {
    userState = localStorageUserState
}

const store = createStore({
    state: {
        user: userState,
        pageNotifications: {},
        lastPageNotificationIndex: 0,
        isDarkThemeEnabled: 'true' === window.localStorage.getItem('isDarkThemeEnabled'),
        isNavCollapsed: 'true' === window.localStorage.getItem('isNavCollapsed')
    },
    getters: {
        auth(state) {
            return state.user
        },
        hasAuthUserRole: (state) => (checkRoles) => {
            if (!Array.isArray(checkRoles)) {
                checkRoles = [ checkRoles ]
            }

            for (let key in state.user?.roles ?? []) {
                let userRole = state.user.roles[key]

                for (let key2 in checkRoles) {
                    if (checkRoles[key2] === userRole) {
                        return true
                    }
                }
            }

            return false
        },
        getPageNotifications(state) {
            return state.pageNotifications
        },
        isDarkThemeEnabled(state) {
            return state.isDarkThemeEnabled
        },
        isNavCollapsed(state) {
            return state.isNavCollapsed
        }
    },
    mutations: {
        resetAuth(state) {
            state.user = defaultUserState
            window.localStorage.removeItem('user')
        },
        newAuth(state, newUser) {
            state.user = newUser
            window.localStorage.setItem('user', JSON.stringify(newUser))
        },
        addPageNotification(state, notification) {
            const newIndex = state.lastPageNotificationIndex + 1
            state.lastPageNotificationIndex = newIndex

            const newIndexKey = 'index_' + newIndex
            state.pageNotifications[newIndexKey] = notification

            // Remove notification after 3 seconds
            setTimeout(() => {
                delete state.pageNotifications[newIndexKey]
            }, 5000)
        },
        enableDarkTheme(state) {
            state.isDarkThemeEnabled = true
            window.localStorage.setItem('isDarkThemeEnabled', 'true')
        },
        disableDarkTheme(state) {
            state.isDarkThemeEnabled = false
            window.localStorage.setItem('isDarkThemeEnabled', 'false')
        },
        collapseNav(state) {
            state.isNavCollapsed = true
            window.localStorage.setItem('isNavCollapsed', 'true')
        },
        expandNav(state) {
            state.isNavCollapsed = false
            window.localStorage.setItem('isNavCollapsed', 'false')
        }
    }
})

export default store
