<template>
  <div>
    <h1>
      Активные заказы
    </h1>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>