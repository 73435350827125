const getRoles = () => {
    return {
        SUPER_ADMIN: 'super_admin_role',
        HANDLE_ORDERS: 'handle_orders_role',
        UPLOAD_PRICELISTS: 'upload_pricelists_role',
        EDIT_PRICELISTS: 'edit_pricelists_role',
        EDIT_FEEDS: 'edit_feeds_role',
        EDIT_SETTINGS: 'edit_settings_role',
        EDIT_BRANCH_OFFICES_COMPANIES: 'edit_branch_offices_companies_role',
        EDIT_ADMINS: 'edit_admins_role'
    }
}

export default getRoles