<template>
  <div v-if="isRegistered" class="page-center ta-c">
    <h1>
      Спасибо за интерес к нашей системе!
    </h1>
    <h2>
      Ваш аккаунт успешно создан!
    </h2>
    <div class="mb-primary">
      Теперь <b>Вам необходимо активировать его</b>
    </div>
    <div class="mb-primary">
      На указанный E-mail было отправлено письмо с дальнейшими инструкциями и активационной ссылкой
    </div>
    <div class="mb-primary">
      <b>Если письмо не пришло, проверьте папку «Спам»</b>
    </div>
    <div class="mb-primary">
      До активации аккаунт будет недоступен!
    </div>
  </div>
  <div v-else class="page-form-center">
    <h1>
      Регистрация аккаунта
    </h1>
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :submit-button-text="submitButtonText"
          :on-success="onSuccess"
    >
      <template v-slot:signup_links_block>
        <router-link :to="{ name: 'sign_in' }">
          Вернуться к форме входа
        </router-link>
      </template>
    </Form>
  </div>
</template>

<script>
import authSuccessCallback from "@/pages/PlatformAuth/authSuccessCallback";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";

export default {
  components: {Form},
  data() {
    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('signup', 'email', {
          label: 'E-mail',
          type: 'text'
        })
        .addFieldSetField('signup', 'password', {
          label: 'Пароль',
          type: 'password'
        })
        .addFieldSetField('signup', 'signup_links_block', {
          slot: 'signup_links_block',
          type: 'htmlblock'
        })

    return {
      isRegistered: false,
      submitButtonText: 'Зарегистрироваться',
      onSuccess: (response) => {
        this.isRegistered = true
      },
      formBuilder: formBuilder,
      apiMethod: 'signup',
    }
  }
}
</script>