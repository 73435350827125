<template>
  <div class="page-form">
    <h1 v-if="isCreate">
      Новая компания
    </h1>
    <h1 v-else>
      Редактирование компании
    </h1>
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :submit-button-text="submitButtonText"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    ></Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import Form from "@/components/UI/Form/Form.vue";
import FormBuilder from "@/components/UI/Form/FormBuilder";

export default {
  components: {Form},
  data() {
    const companyId = this.$route.params?.companyId ?? null
    const isCreate = null === companyId
    const successCallback = (response) => {
      this.$router.push({ name: 'companies.all' })
    }
    const cancelCallback = (response) => {
      this.$router.push({ name: 'companies.all' })
    }

    const formBuilder = new FormBuilder()

    formBuilder
        .addFieldSetField('company', 'company_type', {
          label: 'Форма',
          type: 'select',
          required: true,
          options: [
            {
              text: 'Выбрать..',
              hidden: true
            }
          ]
        })
        .addFieldSetField('company', 'company_name', {
          label: 'Имя компании',
          getLabel: () => {
            return this.isEntrepreneur() ? 'ФИО ИП' : 'Имя компании'
          },
          type: 'text',
          required: true
        })
        .addFieldSetField('company', 'business_address', {
          label: 'Юридический адрес',
          type: 'textarea',
          required: true
        })
        .addFieldSetField('company', 'physical_address', {
          label: 'Фактический адрес',
          type: 'textarea',
          required: true
        })
        .addFieldSetField('company', 'tin', {
          label: 'ИНН',
          type: 'text',
          max_length: 15,
          required: true
        })
        .addFieldSetField('company', 'trrc', {
          label: 'КПП',
          type: 'text',
          max_length: 9,
          required: true,
          isHidden: () => {
            return this.isEntrepreneur()
          }
        })
        .addFieldSetField('company', 'psrn', {
          label: 'ОГРН',
          type: 'text',
          max_length: 20,
          required: true
        })
        .addFieldSetField('company', 'account', {
          label: 'Расчетный счет',
          type: 'text',
          max_length: 25,
          required: true
        })
        .addFieldSetField('company', 'corr_account', {
          label: 'Корреспондентский счет',
          type: 'text',
          max_length: 25,
          required: true
        })
        .addFieldSetField('company', 'bic', {
          label: 'БИК',
          type: 'text',
          max_length: 15,
          required: true
        })
        .addFieldSetField('company', 'bank_name', {
          label: 'Наименование банка',
          type: 'textarea',
          required: true
        })

    return {
      isCreate: isCreate,
      submitButtonText: isCreate ? 'Создать' : 'Сохранить',
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      companyId: companyId,
      apiMethod: isCreate ? 'companies/create' : `companies/${companyId}/update`,
    }
  },
  methods: {
    isEntrepreneur() {
      return 4 === parseInt(this.formBuilder.getFieldValueByFieldName('company_type'))
    },
    async getCompany() {
      const successCallback = (response) => {
        if (response.data?.company) {
          for (let fieldName in response.data.company) {
            if (this.formBuilder.hasField(fieldName)) {
              this.formBuilder.updateFieldParam(fieldName, 'value', response.data.company[fieldName])
            }
          }
        }
      }

      await apiTransport({
        apiMethod: 'companies/' + this.companyId,
        successCallback: successCallback
      })
    },
    async getCompanyTypes() {
      const successCallback = (response) => {
        if (response.data?.company_types) {
          for (let key in response.data.company_types) {
            this.formBuilder.addSelectOption('company_type', {
              value: response.data.company_types[key]?.id,
              text: response.data.company_types[key]?.label
            })

          }
        }
      }

      await apiTransport({
        apiMethod: 'companies/types',
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    if (!this.isCreate) {
      this.$emit('startLoading')
    }
  },
  mounted() {
    // Fetch company types first important for mapping
    this.getCompanyTypes()
        .then(async () => {
          if (!this.isCreate) {
            await this.getCompany()
          }
        })
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>