<template>
  <div class="page-small">
    <h1>
      Группы цен
    </h1>
    <div class="info-blocks">
      <div class="buttons-wrap">
        <button @click="this.$router.push({ name: 'pricesgroups.add' })">
          Добавить группу цен
        </button>
      </div>
      <div v-for="pricesGroup in pricesGroups ?? []" class="info-block">
        <div class="icon-buttons">
          <span @click="this.$router.push({ name: 'pricesgroups.current.edit', params: { pricesGroupId: pricesGroup.id } })"
                class="icomoon-icon-pencil"
          ></span>
          <span @click="this.$router.push({ name: 'pricesgroups.current.delete', params: { pricesGroupId: pricesGroup.id } })"
              class="icomoon-icon-bin"
          ></span>
        </div>
        <h3>
          {{ pricesGroup.name }}
        </h3>
        <div>
          <span class="grey-color">Филиал:</span> {{ pricesGroup.branch_office.name }}
        </div>
      </div>
      <div v-if="null !== pricesGroups && !pricesGroups.length">
        Группы цен не добавлены
      </div>
    </div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";

export default {
  data() {
    return {
      pricesGroups: null
    }
  },
  methods: {
    async getPricesGroups() {
      const successCallback = (response) => {
        this.pricesGroups = response.data?.prices_groups ?? []
      }

      await apiTransport({
        apiMethod: 'prices-groups',
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getPricesGroups()
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>