<template>
  <div class="page-form">
    <h1>
      Настройки предложений
    </h1>
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    ></Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";

export default {
  components: {Form},
  data() {
    const feedId = this.$route.params.feedId

    const goToFeedSummary = () => {
      this.$router.push({ name: 'feeds.current', params: { feedId: feedId } })
    }

    const successCallback = (response) => {
      goToFeedSummary()
    }
    const cancelCallback = (response) => {
      goToFeedSummary()
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('offers_config', 'min_price', {
          label: 'Добавлять предложения с ценами от',
          type: 'number',
          required: true
        })
        .addFieldSetField('offers_config', 'max_price', {
          label: 'Добавлять предложения с ценами до',
          type: 'number',
          required: true
        })
        .addFieldSetField('offers_config', 'max_count_enabled', {
          label: 'Ограничить количество предложений в фиде',
          type: 'switcher'
        })
        .addFieldSetField('offers_config', 'max_count', {
          label: 'Максимальное количество предложений в фиде *',
          type: 'number',
          isHidden: () => {
            return this.isMaxCountEnabled()
          }
        })
        .addFieldSetField('offers_config', 'duplicates_disabled', {
          label: 'Не добавлять дубликаты',
          description: 'Предложения в фиде без повторов',
          type: 'switcher'
        })
        .addFieldSetField('offers_config', 'duplicates_min_price_enabled', {
          label: 'Добавлять позиции с минимальными ценами',
          description: 'Из повторяющихся предложений будет добавлено самое дешевое',
          type: 'switcher'
        })

    return {
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      apiMethod: `feeds/${feedId}/offers-config/update`,
      feedId: feedId
    }
  },
  methods: {
    isMaxCountEnabled() {
      return !this.formBuilder.getFieldValueByFieldName('max_count_enabled')
    },
    async getFeedOffersConfig() {
      const successCallback = (response) => {
        if (response.data?.feed_offers_config) {
          const offersConfig = response.data.feed_offers_config

          for (let fieldName in offersConfig) {
            if (this.formBuilder.hasSwitcherField(fieldName)) {
              this.formBuilder.updateFieldParam(fieldName, 'isEnabled', offersConfig[fieldName])
            } else if (this.formBuilder.hasField(fieldName)) {
              this.formBuilder.updateFieldParam(fieldName, 'value', offersConfig[fieldName])
            }
          }

          if (null !== offersConfig.max_count) {
            this.formBuilder.updateFieldParam('max_count_enabled', 'isEnabled', true)
          }
        }
      }

      await apiTransport({
        apiMethod: `feeds/${this.feedId}/offers-config`,
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getFeedOffersConfig()
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>