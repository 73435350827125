<template>
  <select :id="fieldName"
          :disabled="!!field?.disabled"
          :value="value"
          @change="$emit('update:value', $event.target.value)"
  >
    <option v-for="option in field?.options ?? []"
            :value="option?.value ?? ''"
            :disabled="!!option?.disabled"
            :hidden="!!option?.hidden"
    >
      {{ option.text }}
    </option>
  </select>
  <label :for="fieldName">
    {{ field.label }}
  </label>
</template>

<script>
export default {
  props: {
    fieldName: {
      type: String,
      default: ''
    },
    field: {
      type: Object,
      default: {
        label: ''
      }
    },
    value: {
      default: ''
    }
  }
}
</script>