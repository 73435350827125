<template>
  <div v-if="null === newPasswordHash || null === email || false === isPasswordUpdated"
       class="page-center ta-c"
  >
    <h1>
      Что-то пошло не так :(
    </h1>
    <div>
      Попробуйте запросить восстановление пароля еще раз
    </div>
  </div>
  <div v-else-if="true === isPasswordUpdated" class="page-center ta-c">
    <h1>
      Пароль успешно изменен!
    </h1>
    <div class="mb-primary">
      Теперь необходимо войти в панель управления с новым паролем
    </div>
    <button @click="this.$router.push({ name: 'sign_in' })">
      Войти
    </button>
  </div>
  <div v-else class="page-form-center">
    <h1>
      Установите новый пароль
    </h1>
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :submit-button-text="submitButtonText"
          :on-success="onSuccess"
          :on-error="onError"
    >
      <template v-slot:new_password_links_block>
        <div>
          <router-link :to="{ name: 'sign_in' }">
            Вернуться к форме входа
          </router-link>
        </div>
      </template>
    </Form>
  </div>
</template>

<script>
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";
import {HttpStatusCode} from "axios";

export default {
  components: {Form},
  data() {
    const email = this.$route.query?.email ?? null
    const newPasswordHash = this.$route.query?.key ?? null

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('new_password', 'new_password', {
          label: 'Придумайте новый пароль',
          type: 'password',
          required: true
        })
        .addFieldSetField('new_password', 'repeat_new_password', {
          label: 'Повторите пароль',
          type: 'password',
          required: true
        })
        .addFieldSetField('new_password', 'new_password_links_block', {
          slot: 'new_password_links_block',
          type: 'htmlblock'
        })
        .addFixedPayload({
          email: email,
          key: newPasswordHash
        })

    return {
      email: email,
      newPasswordHash: newPasswordHash,
      isPasswordUpdated: null,
      submitButtonText: 'Сохранить пароль',
      onSuccess: (response) => {
        this.isPasswordUpdated = true
        this.$store.commit('resetAuth') // old authorization params is invalid now
      },
      onError: (err) => {
        // On Bad Request response shows errors, on another response enable page error
        if (HttpStatusCode.BadRequest !== err.status) {
          this.isPasswordUpdated = false
        }
      },
      formBuilder: formBuilder,
      apiMethod: 'confirm/new-password',
    }
  }
}
</script>