<template>
  <div class="page-form">
    <h1>
      Удаление пользователя
    </h1>
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :submit-button-text="submitButtonText"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    >
      <template v-slot:delete_user_info>
        <div>
          Вы уверены, что хотите удалить аккаунт <b>{{ userEmail }}</b>?
        </div>
        <div>
          Любые данные, которые связаны с этим аккаунтом, больше не будут иметь с ним связи!
        </div>
        <div>
          <b>Это действие нельзя будет отменить!</b>
        </div>
      </template>
    </Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";

export default {
  components: {Form},
  data() {
    const userId = parseInt(this.$route.params.userId)
    const goToUsersList = () => {
      this.$router.push({ name: 'users.all' })
    }
    const successCallback = (response) => {
      goToUsersList()
    }
    const cancelCallback = (response) => {
      goToUsersList()
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('delete_user_info', 'delete_user_info', {
          slot: 'delete_user_info',
          type: 'infoblock'
        })

    return {
      submitButtonText: 'Да, удалить',
      formBuilder: formBuilder,
      onSuccess: successCallback,
      onCancel: cancelCallback,
      userId: userId,
      apiMethod: `users/${userId}/delete`,
      userEmail: ''
    }
  },
  methods: {
    async getUser() {
      const successCallback = (response) => {
        if (response.data?.user) {
          this.userEmail = response.data.user.email
        }
      }

      await apiTransport({
        apiMethod: `users/${this.userId}`,
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getUser()
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>