class FormBuilder {
    form = {}
    fieldsToFormMap = {}
    fieldsToErrorsFieldsMap = {}
    listTypeFieldsIndexes = {}
    fieldsOfLinesFieldsIndexes = {}
    isSubmitDisabledCallback = null
    submitDisabled = false
    fixedPayload = {}

    isSubmitDisabled() {
        return this.submitDisabled
    }

    setSubmitDisabledCallback(callback) {
        this.isSubmitDisabledCallback = callback

        return this
    }

    addFieldset(fieldSetKey) {
        this.form[fieldSetKey] = {
            fields: {}
        }

        return this
    }

    addFieldSetField(fieldSetName, fieldName, field, isListType) {
        if (!this.form.hasOwnProperty(fieldSetName)) {
            this.addFieldset(fieldSetName)
        }

        if ('switcher' === field.type) {
            field.value = field?.value ?? 1
        }

        let renderFieldName = fieldName

        if (!!isListType) {
            if (!this.listTypeFieldsIndexes.hasOwnProperty(fieldName)) {
                this.listTypeFieldsIndexes[fieldName] = -1
            }

            this.listTypeFieldsIndexes[fieldName]++

            const currentFieldId = this.listTypeFieldsIndexes[fieldName]

            renderFieldName = fieldName + '_' + currentFieldId

            this.form[fieldSetName].isListType = true

            field.removeFieldFromFieldSet = () => {
                this.removeField(renderFieldName)
            }

            field.listFieldName = fieldName
        }

        this.form[fieldSetName].fields[renderFieldName] = field

        this.fieldsToFormMap[renderFieldName] = fieldSetName

        // Map this field errors to another field
        if (field?.mapErrorsFieldName) {
            let resultFieldName = field?.listFieldName ?? fieldName
            this.fieldsToErrorsFieldsMap[resultFieldName] = field.mapErrorsFieldName
        }

        // Map another fields errors to this field
        if (field?.mapErrorsOfFieldNames) {
            for (let key in field.mapErrorsOfFieldNames) {
                this.fieldsToErrorsFieldsMap[field.mapErrorsOfFieldNames[key]] = renderFieldName
            }
        }

        return this
    }

    addFieldSetFieldsLine(fieldSetName, fieldsOfLineName, fieldsOfLine, isListType) {
        if (!this.form.hasOwnProperty(fieldSetName)) {
            this.addFieldset(fieldSetName)
        }

        let renderFieldsOfLineName = fieldsOfLineName

        if (!!isListType) {
            if (!this.listTypeFieldsIndexes.hasOwnProperty(fieldsOfLineName)) {
                this.listTypeFieldsIndexes[fieldsOfLineName] = -1
            }

            this.listTypeFieldsIndexes[fieldsOfLineName]++

            const currentFieldsOfLineId = this.listTypeFieldsIndexes[fieldsOfLineName]

            renderFieldsOfLineName = fieldsOfLineName + '_' + currentFieldsOfLineId

            this.form[fieldSetName].isListType = true

            fieldsOfLine.removeFieldFromFieldSet = () => {
                this.removeField(renderFieldsOfLineName)
            }

            fieldsOfLine.listFieldsOfLineName = fieldsOfLineName

            // Add render field names (for prevent duplicates ids on page)
            for (let fieldKey in fieldsOfLine.fields) {
                let field = fieldsOfLine.fields[fieldKey]

                let fieldOfLineName = field.fieldName

                if (!this.fieldsOfLinesFieldsIndexes.hasOwnProperty(fieldOfLineName)) {
                    this.fieldsOfLinesFieldsIndexes[fieldOfLineName] = -1
                }

                this.fieldsOfLinesFieldsIndexes[fieldOfLineName]++

                let currentFieldOfLineId = this.fieldsOfLinesFieldsIndexes[fieldOfLineName]

                field.renderFieldName = fieldOfLineName + '_' + currentFieldOfLineId
            }
        }

        this.form[fieldSetName].fields[renderFieldsOfLineName] = fieldsOfLine

        this.fieldsToFormMap[renderFieldsOfLineName] = fieldSetName

        if (fieldsOfLine?.mapErrorsFieldName) {
            this.fieldsToErrorsFieldsMap[fieldsOfLineName] = fieldsOfLine.mapErrorsFieldName
        }

        return this
    }

    addFieldSetParams(fieldSetNames, params) {
        const names = Array.isArray(fieldSetNames) ? fieldSetNames : [fieldSetNames]

        for (let key in names) {
            let fieldSetName = names[key]

            if (!this.form.hasOwnProperty(fieldSetName)) {
                this.addFieldset(fieldSetName)
            }

            for (let paramKey in params) {
                this.form[fieldSetName][paramKey] = params[paramKey]
            }
        }

        return this
    }

    build() {
        for (let fieldSetName in this.form) {
            let fieldSet = this.form[fieldSetName]

            // Update fieldset hidden status
            fieldSet.hidden = !!(fieldSet?.isHidden && fieldSet.isHidden())

            for (let fieldName in fieldSet.fields) {
                let field = fieldSet.fields[fieldName]

                // Update field hidden status
                field.hidden = !!(field?.isHidden && field.isHidden())

                // Update field disabled status
                field.disabled = !!(field?.isDisabled && field.isDisabled())

                // Update switcher disabled enabled status
                if ('switcher' === field.type) {
                    field.disabledEnabled = !!(field?.isDisabledEnabled && field.isDisabledEnabled())
                }

                // Update label, if update method present
                if (field?.getLabel) {
                    field.label = field.getLabel()
                }
            }
        }

        // Update submit disabled
        this.submitDisabled = null !== this.isSubmitDisabledCallback && this.isSubmitDisabledCallback()

        return this
    }

    getForm() {
        return this.form
    }

    updateFieldParam(fieldName, param, value) {
        const field = this.getField(fieldName)

        field[param] = value ?? '' // may be null

        return this
    }

    getFieldParam(fieldName, param) {
        const field = this.getField(fieldName)

        if (!field.hasOwnProperty(param)) {
            return null
        }

        return field[param]
    }

    removeField(fieldName) {
        const fieldSetKey = this.getFieldSetKeyByField(fieldName)

        delete this.form[fieldSetKey].fields[fieldName]

        return this
    }

    getField(fieldName) {
        const fieldSetKey = this.getFieldSetKeyByField(fieldName)

        if (!this.form[fieldSetKey].fields[fieldName]) {
            throw new Error(`Field ${fieldName} not set in form`)
        }

        return this.form[fieldSetKey].fields[fieldName]
    }

    getFieldValueByFieldName(fieldName) {
        return this.getFieldValue(this.getField(fieldName))
    }

    addSelectOption(fieldName, option) {
        const field = this.getField(fieldName)

        if ('select' !== field?.type) {
            throw new Error(`Field ${fieldName} is not a select field`)
        }

        if (!field.hasOwnProperty('options')) {
            field.options = []
        }

        field.options.push(option)

        return this
    }

    removeFieldSet(fieldSetKey) {
        if (!Object.keys(this.form[fieldSetKey].fields).length) {
            throw new Error(`FieldSet ${fieldSetKey} not set in form`)
        }

        delete this.form[fieldSetKey]

        return this
    }

    getFieldSetKeyByField(fieldName) {
        if (!this.hasField(fieldName)) {
            throw new Error(`Field ${fieldName} not set in form`)
        }

        return this.fieldsToFormMap[fieldName]
    }

    hasField(fieldName) {
        return this.fieldsToFormMap.hasOwnProperty(fieldName)
    }

    hasSwitcherField(fieldName) {
        return this.hasField(fieldName) && 'switcher' === this.getField(fieldName)?.type
    }

    hasFieldForErrors(fieldName) {
        return this.hasField(fieldName) || this.hasErrorsMappedField(fieldName)
    }

    hasErrorsMappedField(fieldName) {
        return this.fieldsToErrorsFieldsMap.hasOwnProperty(fieldName)
    }

    cleanAllErrors() {
        for (let fieldSetName in this.form) {
            for (let fieldName in this.form[fieldSetName].fields) {
                this.form[fieldSetName].fields[fieldName].errors = []
            }
        }

        return this
    }

    setFieldErrors(fieldName, errors) {
        let fieldNameForErrors = fieldName
        if (this.hasErrorsMappedField(fieldName)) {
            fieldNameForErrors = this.fieldsToErrorsFieldsMap[fieldName]
        }

        this.getField(fieldNameForErrors).errors = errors

        return this
    }

    addFixedPayload(payload) {
        for (let fieldName in payload) {
            this.fixedPayload[fieldName] = payload[fieldName]
        }

        return this
    }

    getPayload() {
        const payload = this.fixedPayload

        for (let fieldSetName in this.form) {
            if (this.form[fieldSetName]?.hidden) {
                continue
            }

            for (let fieldName in this.form[fieldSetName].fields) {
                let field = this.form[fieldSetName].fields[fieldName]

                if (field?.hidden
                    || 'label' === field.type
                    || 'infoblock' === field.type
                    || 'htmlblock' === field.type
                ) {
                    continue
                }

                if (field.hasOwnProperty('listFieldName')) {
                    if (!payload.hasOwnProperty(field.listFieldName)) {
                        payload[field.listFieldName] = []
                    }

                    payload[field.listFieldName].push(this.getFieldValue(field))
                } else {
                    payload[fieldName] = this.getFieldValue(field)
                }
            }
        }

        return payload
    }

    getFieldValue(field) {
        let fieldValue

        if (field.hasOwnProperty('fields')) {
            fieldValue = {}

            for (let fieldOfLineKey in field.fields) {
                let fieldOfLine = field.fields[fieldOfLineKey]

                fieldValue[fieldOfLine.fieldName] = this.getFieldValue(fieldOfLine)
            }
        } else {
            fieldValue = field?.value ?? ''
            if ('switcher' === field.type && !field?.isEnabled) {
                fieldValue = ''
            }
        }

        return fieldValue
    }
}

export default FormBuilder
