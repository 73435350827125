<template>

</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.commit('resetAuth')
      this.$router.push({ name: 'sign_in' })
    }
  },
  beforeMount() {
    this.logout()
  }
}
</script>