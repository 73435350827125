<template>
  <div class="page-form">
    <h1>
      Удаление группы цен
    </h1>
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :submit-button-text="submitButtonText"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    >
      <template v-slot:delete_prices_group_info>
        <div>
          Вы уверены, что хотите удалить группу цен <b>{{ pricesGroupName }}</b>?
        </div>
        <div>
          Вместе с группой цен будут удалены <b>все</b> наценки прайс-листов, использующих данную группу цен.
        </div>
        <div>
          <b>Это действие нельзя будет отменить!</b>
        </div>
      </template>
    </Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";

export default {
  components: {Form},
  data() {
    const pricesGroupId = parseInt(this.$route.params.pricesGroupId)
    const goToPricesGroupsList = () => {
      this.$router.push({ name: 'pricesgroups.all' })
    }
    const successCallback = (response) => {
      goToPricesGroupsList()
    }
    const cancelCallback = (response) => {
      goToPricesGroupsList()
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('delete_prices_group_info', 'delete_prices_group_info', {
          slot: 'delete_prices_group_info',
          type: 'infoblock'
        })

    return {
      submitButtonText: 'Да, удалить',
      formBuilder: formBuilder,
      onSuccess: successCallback,
      onCancel: cancelCallback,
      pricesGroupId: pricesGroupId,
      apiMethod: `prices-groups/${pricesGroupId}/delete`,
      pricesGroupName: ''
    }
  },
  methods: {
    async getPricesGroup() {
      const successCallback = (response) => {
        if (response.data?.prices_group) {
          this.pricesGroupName = response.data.prices_group.name
        }
      }

      await apiTransport({
        apiMethod: `prices-groups/${this.pricesGroupId}`,
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getPricesGroup()
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>