<template>
  <div class="page-form">
    <h1>
      Настройки поставки
    </h1>
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    >
      <template v-slot:delivery_config_info>
        <div>
          <b>Данные настройки будут применены:</b>
        </div>
        <ul>
          <li>
            ко всем позициям из прайс-листа, если в прайс-листе отсутствует столбец со сроками поставки
          </li>
          <li>
            ко всем позициям из прайс-листа, для которых в столбце со сроком поставки отсутствует информация
          </li>
        </ul>
      </template>
    </Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";

export default {
  components: {Form},
  data() {
    const pricelistId = this.$route.params.pricelistId

    const goToPricelistSummary = () => {
      this.$router.push({ name: 'pricelists.current', params: { pricelistId: pricelistId } })
    }

    const successCallback = (response) => {
      goToPricelistSummary()
    }
    const cancelCallback = (response) => {
      goToPricelistSummary()
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('delivery_config_info', 'delivery_config_info', {
          slot: 'delivery_config_info',
          type: 'infoblock'
        })
        .addFieldSetField('delivery_period_type', 'delivery_period_type', {
          label: 'Срок поставки',
          type: 'radio',
          required: true,
          value: 1,
          options: [
            {
              value: 0,
              text: 'в часах'
            },
            {
              value: 1,
              text: 'в днях'
            }
          ]
        })
        .addFieldSetField('min_delivery', 'min_delivery', {
          label: 'Минимальный срок поставки',
          type: 'number',
          required: true
        })
        .addFieldSetField('max_delivery', 'max_delivery', {
          label: 'Максимальный срок поставки',
          type: 'number',
          required: true
        })

    return {
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      apiMethod: `pricelists/${pricelistId}/delivery/update`,
      pricelistId: pricelistId
    }
  },
  methods: {
    async getPricelistMainInfo() {
      const successCallback = (response) => {
        if (response.data?.pricelist_delivery) {
          const deliveryInfo = response.data.pricelist_delivery

          this.formBuilder.updateFieldParam('delivery_period_type', 'value', deliveryInfo?.period_type)
          this.formBuilder.updateFieldParam('min_delivery', 'value', deliveryInfo?.min)
          this.formBuilder.updateFieldParam('max_delivery', 'value', deliveryInfo?.max)
        }
      }

      await apiTransport({
        apiMethod: `pricelists/${this.pricelistId}/delivery`,
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getPricelistMainInfo()
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>