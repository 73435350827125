<template>
  <div class="mobile_nav_wrap">
    <div class="mobile_nav">
      <ul>
        <li>
          <label for="mobile_nav_toggle_checkbox">
            <span class="icomoon-icon-menu"></span>
            <span class="mobile_nav_btn_txt">
						Меню
					</span>
          </label>
        </li>
        <li>
          <router-link :to="{ name: 'feeds.all' }">
            <span class="icomoon-icon-users"></span>
            <span class="mobile_nav_btn_txt">
						  Фиды
            </span>
          </router-link>
        </li>
        <li id="mobile_nav_active_orders_btn">
          <router-link :to="{ name: 'orders.active' }">
            <span class="icomoon-icon-list"></span>
            <span class="mobile_nav_btn_txt">
						  Заказы
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'pricelists.all' }">
            <span class="icomoon-icon-files-empty"></span>
            <span class="mobile_nav_btn_txt">
						  Прайсы
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'logout' }">
            <span class="icomoon-icon-exit"></span>
            <span class="mobile_nav_btn_txt">
						  Выйти
            </span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.mobile_nav_wrap {
  background-color: var(--mobile-nav-bg);
  backdrop-filter: blur(10px);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9996;
  line-height: 1;
  padding-bottom: env(safe-area-inset-bottom);
  box-shadow: var(--mobile-nav-box-shadow);
}
.mobile_nav {
  height: var(--mobile-nav-height);
}
.mobile_nav > ul {
  display: flex;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}
.mobile_nav > ul li {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  height: 100%;
}
.mobile_nav > ul a,
.mobile_nav > ul label {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  color: var(--mobile-nav-color);
  height: 100%;
  position: relative;
  cursor: pointer;
}
.mobile_nav > ul > li > a .new_updates {
  position: absolute;
  right: 7px;
  top: 3px;
}
.mobile_nav span[class^="icomoon-icon-"],
.mobile_nav span[class*=" icomoon-icon-"] {
  font-size: 20px;
}
.mobile_nav_btn_txt {
  font-size: 11px;
  margin-top: 2px;
}
@media (min-width: 768px) {
  .mobile_nav_wrap {
    display: none;
  }
}
</style>