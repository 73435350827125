<template>
  <textarea :id="fieldName"
            :rows="field?.rows ?? 3"
            :disabled="!!field?.disabled"
            :value="value"
            @change="$emit('update:value', $event.target.value)"
  ></textarea>
  <label :for="fieldName">
    {{ field.label }}
  </label>
</template>

<script>
export default {
  props: {
    fieldName: {
      type: String,
      default: ''
    },
    field: {
      type: Object,
      default: {
        label: ''
      }
    },
    value: {
      default: ''
    }
  }
}
</script>