<template>
  <div class="page-form">
    <h1>
      Удаление фида
    </h1>
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :submit-button-text="submitButtonText"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    >
      <template v-slot:delete_feed_info>
        <div>
          Вы уверены, что хотите удалить фид <b>{{ feedName }}</b>?
        </div>
        <div>
          <b>Это действие нельзя будет отменить!</b>
        </div>
      </template>
    </Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import Form from "@/components/UI/Form/Form.vue";
import FormBuilder from "@/components/UI/Form/FormBuilder";

export default {
  components: {Form},
  data() {
    const feedId = parseInt(this.$route.params.feedId)
    const goToFeedsList = () => {
      this.$router.push({ name: 'feeds.all' })
    }
    const successCallback = (response) => {
      goToFeedsList()
    }
    const cancelCallback = (response) => {
      goToFeedsList()
    }

    const formBuilder = new FormBuilder()

    formBuilder
        .addFieldSetField('delete_feed_info', 'delete_feed_info', {
          slot: 'delete_feed_info',
          type: 'infoblock'
        })

    return {
      submitButtonText: 'Да, удалить',
      formBuilder: formBuilder,
      onSuccess: successCallback,
      onCancel: cancelCallback,
      feedId: feedId,
      apiMethod: `feeds/${feedId}/delete`,
      feedName: ''
    }
  },
  methods: {
    async getFeed() {
      const successCallback = (response) => {
        if (response.data?.feed) {
          const feed = response.data.feed

          this.feedName = feed?.name ? feed.name : 'Без названия'
        }
      }

      await apiTransport({
        apiMethod: `feeds/${this.feedId}`,
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getFeed()
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>