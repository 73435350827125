function middlewarePipeline(context, middlewares, index) {
    const nextMiddleware = middlewares[index]

    if (!nextMiddleware) {
        return context.next
    }

    const nextPipeline = middlewarePipeline(
        context, middlewares, index + 1
    )

    return nextMiddleware({ ...context, next: nextPipeline })
}

export default middlewarePipeline