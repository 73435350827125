import getRoles from "@/pages/Platform/Users/Enums/getRoles";

const roles = getRoles()

export default function checkEditPricelistsRole({ next, store }) {
    if (!store.getters.hasAuthUserRole([ roles.SUPER_ADMIN, roles.EDIT_BRANCH_OFFICES_COMPANIES ])) {
        return next({
            name: 'notfoundpage.authenticated'
        })
    }

    return next()
}
