<template>
  <div class="page-form">
    <h1>
      Настройки аккаунта
    </h1>
    <Form :form-builder="personalInfoFormBuilder"
          :api-method="personalInfoFormApiMethod"
    ></Form>
    <h2>
      E-mail
    </h2>
    <Form :form-builder="emailFormBuilder"
          :api-method="emailFormApiMethod"
          :submit-button-text="emailFormSubmitButtonText"
          :on-success="emailFormOnSuccess"
    ></Form>
    <h2>
      Изменение пароля
    </h2>
    <Form :form-builder="passwordFormBuilder"
          :api-method="passwordFormApiMethod"
          :submit-button-text="passwordFormSubmitButtonText"
          :on-success="passwordFormOnSuccess"
    ></Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";

export default {
  components: {Form},
  data() {
    const emailFormOnSuccess = () => {
      this.emailFormBuilder.updateFieldParam('email', 'value', '')
    }

    const passwordFormOnSuccess = () => {
      this.passwordFormBuilder.updateFieldParam('old_password', 'value', '')
      this.passwordFormBuilder.updateFieldParam('new_password', 'value', '')
      this.passwordFormBuilder.updateFieldParam('repeat_new_password', 'value', '')
    }

    const personalInfoFormBuilder = new FormBuilder()
    personalInfoFormBuilder
        .addFieldSetField('name', 'name', {
          label: 'Имя',
          type: 'text',
          required: true
        })
        .addFieldSetField('last_name', 'last_name', {
          label: 'Фамилия',
          type: 'text',
          required: true
        })
        .addFieldSetField('middle_name', 'middle_name', {
          label: 'Отчество',
          type: 'text',
          required: true
        })

    const emailFormBuilder = new FormBuilder()
    emailFormBuilder
        .addFieldSetField('email_label', 'email_label', {
          label: '',
          type: 'label'
        })
        .addFieldSetField('email', 'email', {
          label: 'Новый E-mail',
          type: 'text',
          required: true
        })

    const passwordFormBuilder = new FormBuilder()
    passwordFormBuilder
        .addFieldSetField('old_password', 'old_password', {
          label: 'Старый пароль',
          type: 'password',
          required: true
        })
        .addFieldSetField('new_password', 'new_password', {
          label: 'Новый пароль',
          type: 'password',
          required: true
        })
        .addFieldSetField('repeat_new_password', 'repeat_new_password', {
          label: 'Повторите новый пароль',
          type: 'password',
          required: true
        })

    return {
      personalInfoFormBuilder: personalInfoFormBuilder,
      personalInfoFormApiMethod: 'account/personal/update',

      currentEmail: '',
      emailFormBuilder: emailFormBuilder,
      emailFormApiMethod: 'account/email/change-request',
      emailFormSubmitButtonText: 'Изменить E-mail',
      emailFormOnSuccess: emailFormOnSuccess,

      passwordFormBuilder: passwordFormBuilder,
      passwordFormApiMethod: 'account/password/new',
      passwordFormSubmitButtonText: 'Изменить пароль',
      passwordFormOnSuccess: passwordFormOnSuccess
    }
  },
  methods: {
    async getAccount() {
      const successCallback = (response) => {
        if (response.data?.account) {
          for (let fieldName in response.data.account) {
            if (this.personalInfoFormBuilder.hasField(fieldName)) {
              this.personalInfoFormBuilder.updateFieldParam(fieldName, 'value', response.data.account[fieldName])
            }
          }

          this.emailFormBuilder.updateFieldParam(
              'email_label',
              'label',
              'Текущий: ' + response.data.account.email
          )
        }
      }

      await apiTransport({
        apiMethod: 'account/get',
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getAccount()
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>