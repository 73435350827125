<template>
  <div v-if="true === isRequestSent" class="page-center ta-c">
    <h1>
      Восстановление запрошено
    </h1>
    <div class="mb-primary">
      На указанный E-mail было отправлено письмо со ссылкой для восстановления пароля
    </div>
    <div class="mb-primary">
      <b>Если письмо не пришло, проверьте папку «Спам»</b>
    </div>
    <div class="mb-primary">
      Ссылка доступна 2 часа
    </div>
    <button @click="this.$router.push({ name: 'sign_in' })">
      Войти
    </button>
  </div>
  <div v-else class="page-form-center">
    <h1>
      Восстановление пароля
    </h1>
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :submit-button-text="submitButtonText"
          :on-success="onSuccess"
    >
      <template v-slot:restore_password_links_block>
        <div>
          <router-link :to="{ name: 'sign_in' }">
            Вернуться к форме входа
          </router-link>
        </div>
      </template>
    </Form>
  </div>
</template>

<script>
import authSuccessCallback from "@/pages/PlatformAuth/authSuccessCallback";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";

export default {
  components: {Form},
  data() {
    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('restore_password', 'email', {
          label: 'E-mail',
          type: 'text'
        })
        .addFieldSetField('restore_password', 'restore_password_links_block', {
          slot: 'restore_password_links_block',
          type: 'htmlblock'
        })

    return {
      isRequestSent: false,
      submitButtonText: 'Восстановить пароль',
      onSuccess: (response) => {
        this.isRequestSent = true
      },
      formBuilder: formBuilder,
      apiMethod: 'password/restore',
    }
  }
}
</script>