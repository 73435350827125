<template>
  <div class="switcher-wrap"
    :class="{
      'switcher-wrap-disabled': disabled || disabledEnabled,
      'switcher-wrap-disabled-enabled': disabledEnabled
    }"
  >
    <label class="switcher">
      <input type="checkbox"
             :checked="isEnabled"
             :value="value"
             :disabled="disabled || disabledEnabled"
             @change="$emit('update:isEnabled', $event.target.checked)"
      />
      <div class="switcher-slider"></div>
    </label>
    <div class="switcher-checkbox-name-wrap">
      {{ label }}
      <div v-if="description" class="switcher-desc">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    disabledEnabled: {
      type: Boolean,
      default: false
    },
    isEnabled: {
      type: Boolean,
      default: false
    },
    value: {
      default: 1
    },
    label: String,
    description: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>
.nav-footer-wrap .switcher-slider {
  background: var(--main-nav-bg);
}
.nav-footer-wrap input:checked + .switcher-slider {
  background: var(--main-nav-nested-bg);
}
.nav-footer-wrap .switcher-slider::before {
  content: '\263c';
  color: #000;
}
.nav-footer-wrap input:checked + .switcher-slider::before {
  content: '\263e';
}
</style>