<template>
  <div class="page-form">
    <h1>
      Удаление наценок
    </h1>
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :submit-button-text="submitButtonText"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    >
      <template v-slot:delete_markups_config_info>
        <div>
          Вы уверены, что хотите удалить наценки для группы цен <b>{{ pricesGroupName }}</b>?
        </div>
        <div>
          <b>Это действие нельзя будет отменить!</b>
        </div>
      </template>
    </Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";

export default {
  components: {Form},
  data() {
    const pricelistId = parseInt(this.$route.params.pricelistId)
    const markupsConfigId = parseInt(this.$route.params.markupsConfigId)

    const goToMarkupsConfigs = () => {
      this.$router.push({ name: 'pricelists.current.markups_configs.all' })
    }
    const successCallback = (response) => {
      goToMarkupsConfigs()
    }
    const cancelCallback = (response) => {
      goToMarkupsConfigs()
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .addFieldSetField('delete_markups_config_info', 'delete_markups_config_info', {
          slot: 'delete_markups_config_info',
          type: 'infoblock'
        })

    return {
      submitButtonText: 'Да, удалить',
      formBuilder: formBuilder,
      onSuccess: successCallback,
      onCancel: cancelCallback,
      pricelistId: pricelistId,
      markupsConfigId: markupsConfigId,
      apiMethod: `pricelists/${pricelistId}/markups/${markupsConfigId}/delete`,
      pricesGroupName: ''
    }
  },
  methods: {
    async getMarkupsConfig() {
      const successCallback = (response) => {
        if (response.data?.markups_config) {
          this.pricesGroupName = response.data.markups_config.prices_group.name
        }
      }

      await apiTransport({
        apiMethod: `pricelists/${this.pricelistId}/markups/${this.markupsConfigId}`,
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getMarkupsConfig()
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>