<template>
  <div class="page-form">
    <h1>
      Прайс-листы
    </h1>
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    >
      <template v-slot:branch_office_is_not_set_info>
        <div>
          <b>Для фида не указан филиал</b>
        </div>
        <div>
          Прайс-листы для фида могут быть выбраны только из того же филиала, что и фид, поэтому сначала необходимо указать филиал фида.
        </div>
        <div>
          <button @click="this.$router.push({ name: 'feeds.current.edit.main', params: {feedId: feedId} })">
            Указать филиал фида
          </button>
        </div>
      </template>
      <template v-slot:branch_office_no_pricelists>
        <div>
          <b>Для филиала фида не настроено ни одного прайс-листа</b>
        </div>
        <div>
          Прайс-листы для фида могут быть выбраны только из того же филиала, что и фид, поэтому сначала добавьте прайс-листы для филиала «<b>{{ feedBranchOffice.name }}</b>», к которому привязан данный фид.
        </div>
        <div>
          <button @click="this.$router.push({ name: 'pricelists.all' })">
            Перейти к прайс-листам
          </button>
        </div>
      </template>
      <template v-slot:feed_pricelists_info>
        <h3>
          <span class="icomoon-icon-info"></span> Информация
        </h3>
        <div>
          В списке прайс-листов присутствуют как активные, так и отключенные прайс-листы, но отсутствуют те, чья настройка не завершена.
        </div>
        <div>
          Если на момент генерации некоторые из отмеченных прайс-листов будут отключены Вами, то они не будут участвовать в генерации. Поэтому Вы можете отметить в том числе отключенные прайс-листы, но в генерации фидов они начнут участвовать только после их включения Вами.
        </div>
      </template>
    </Form>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";

export default {
  components: {Form},
  data() {
    const feedId = this.$route.params.feedId

    const goToFeedSummary = () => {
      this.$router.push({ name: 'feeds.current', params: { feedId: feedId } })
    }

    const successCallback = (response) => {
      goToFeedSummary()
    }
    const cancelCallback = (response) => {
      goToFeedSummary()
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .setSubmitDisabledCallback(() => {
          return !this.hasAllowedPricelists()
        })
        .addFieldSetField('feed_pricelists_info', 'feed_pricelists_info', {
          slot: 'feed_pricelists_info',
          type: 'infoblock',
          isHidden: () => {
            return !this.hasAllowedPricelists()
          }
        })
        .addFieldSetField('pricelists', 'pricelists_label', {
          label: 'Прайс-листы для формирования фида',
          type: 'label',
          required: true,
          isHidden: () => {
            return !this.hasAllowedPricelists()
          }
        })

    return {
      feedBranchOffice: null,
      allowedPricelists: null, // null by default for prevent disable submit before pricelists fetched
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      apiMethod: `feeds/${feedId}/pricelists/update`,
      feedId: feedId
    }
  },
  methods: {
    hasAllowedPricelists() {
      return 0 !== this.allowedPricelists?.length
    },
    async getFeedPricelists() {
      const successCallback = (response) => {
        if (response.data?.data) {
          const data = response.data.data
          this.feedBranchOffice = data.branch_office

          if (data.pricelists.length) {
            for (let key in data.pricelists) {
              let pricelistFieldName = 'pricelist_' + data.pricelists[key].id

              if (this.formBuilder.hasField(pricelistFieldName)) {
                this.formBuilder.updateFieldParam(pricelistFieldName, 'isEnabled', true)
              }
            }
          } else if (null === this.feedBranchOffice) {
            this.formBuilder.addFieldSetField(
                'pricelists',
                'branch_office_is_not_set_info',
                {
                  slot: 'branch_office_is_not_set_info',
                  type: 'infoblock'
                }
            )
          } else if (!this.allowedPricelists.length) {
            this.formBuilder.addFieldSetField(
                'pricelists',
                'branch_office_no_pricelists',
                {
                  slot: 'branch_office_no_pricelists',
                  type: 'infoblock'
                }
            )
          }
        }
      }

      await apiTransport({
        apiMethod: `feeds/${this.feedId}/pricelists`,
        successCallback: successCallback
      })
    },
    async getPricelists() {
      const successCallback = (response) => {
        this.allowedPricelists = response.data?.pricelists ?? []

        if (this.allowedPricelists.length) {
          for (let key in this.allowedPricelists) {
            let pricelist = this.allowedPricelists[key]
            let pricelistFieldName = `pricelist_${pricelist.id}`

            this.formBuilder.addFieldSetField('pricelists', pricelistFieldName, {
              label: pricelist.name,
              description: pricelist.is_disabled ? 'Прайс-лист отключен' : null,
              type: 'switcher',
              listFieldName: 'pricelists',
              mapErrorsFieldName: 'pricelists_label',
              value: pricelist.id
            })
          }
        }
      }

      await apiTransport({
        apiMethod: 'pricelists',
        queryParams: {
          allowed_for_feed_id: this.feedId
        },
        successCallback: successCallback
      })
    },
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    // Fetch pricelists first important for mapping
    this.getPricelists()
        .then(async () => {
          await this.getFeedPricelists()
        })
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>