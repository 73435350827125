<template>
  <div class="page-form">
    <h1>
      Автозагрузка
    </h1>
    <Form :form-builder="formBuilder"
          :api-method="apiMethod"
          :on-success="onSuccess"
          :on-cancel="onCancel"
    >
      <template v-slot:no_email_profiles_info>
        <div>
          <b>Не настроено ни одного почтового профиля!</b>
        </div>
        <div>
          Для настройки автозагрузки прайс-листа с почты добавьте почтовый профиль
        </div>
        <div>
          <button @click="this.$router.push({ name: 'emailprofiles.add' })">
            Добавить
          </button>
        </div>
      </template>
    </Form>
  </div>

</template>

<script>
import apiTransport from "@/apitransport/apiTransport";
import FormBuilder from "@/components/UI/Form/FormBuilder";
import Form from "@/components/UI/Form/Form.vue";

export default {
  components: {Form},
  data() {
    const pricelistId = this.$route.params.pricelistId

    const goToPricelistSummary = () => {
      this.$router.push({ name: 'pricelists.current', params: { pricelistId: pricelistId } })
    }

    const successCallback = (response) => {
      goToPricelistSummary()
    }
    const cancelCallback = (response) => {
      goToPricelistSummary()
    }

    const formBuilder = new FormBuilder()
    formBuilder
        .setSubmitDisabledCallback(() => {
          return this.isEmailDownloadEnabled() && !this.isEmailProfilesExists()
        })
        .addFieldSetField('main', 'download_type', {
          label: 'Автозагрузка',
          type: 'radio',
          required: true,
          value: 0,
          options: [
            {
              value: 0,
              text: 'отключена'
            },
            {
              value: 1,
              text: 'с почтового ящика'
            },
            {
              value: 2,
              text: 'по ссылке'
            },
            {
              value: 3,
              text: 'с FTP-сервера'
            }
          ]
        })
        .addFieldSetField('main', 'no_email_profiles_info', {
          slot: 'no_email_profiles_info',
          type: 'infoblock',
          isHidden: () => {
            return !this.isEmailDownloadEnabled() || this.isEmailProfilesExists()
          }
        })
        .addFieldSetField('main', 'email_profile_id', {
          label: 'Почтовый профиль',
          type: 'select',
          required: true,
          value: 0,
          options: [
            {
              text: 'Выбрать..',
              value: 0,
              hidden: true
            }
          ],
          isHidden: () => {
            return !this.isEmailDownloadEnabled() || !this.isEmailProfilesExists()
          }
        })
        .addFieldSetField('main', 'email_from', {
          label: 'E-mail отправителя прайс-листа',
          type: 'text',
          required: true,
          max_length: 180,
          isHidden: () => {
            return !this.isEmailDownloadEnabled() || !this.isEmailProfilesExists()
          }
        })
        .addFieldSetField('main', 'email_subject', {
          label: 'Тема письма с прайс-листом',
          type: 'text',
          required: true,
          isHidden: () => {
            return !this.isEmailDownloadEnabled() || !this.isEmailProfilesExists()
          }
        })
        .addFieldSetField('main', 'email_file_name', {
          label: 'Имя файла прайс-листа в письме',
          type: 'text',
          required: true,
          isHidden: () => {
            return !this.isEmailDownloadEnabled() || !this.isEmailProfilesExists()
          }
        })
        .addFieldSetField('main', 'download_link', {
          label: 'Ссылка на скачивание прайс-листа',
          type: 'textarea',
          required: true,
          isHidden: () => {
            return !this.isLinkDownloadEnabled() && !this.isFTPDownloadEnabled()
          }
        })
        .addFieldSetField('main', 'ftp_login', {
          label: 'Логин для FTP-авторизации',
          type: 'text',
          required: false,
          isHidden: () => {
            return !this.isFTPDownloadEnabled()
          }
        })
        .addFieldSetField('main', 'ftp_password', {
          label: 'Пароль для FTP-авторизации',
          type: 'text',
          required: false,
          isHidden: () => {
            return !this.isFTPDownloadEnabled()
          }
        })

    return {
      onSuccess: successCallback,
      onCancel: cancelCallback,
      formBuilder: formBuilder,
      apiMethod: `pricelists/${pricelistId}/download-config/update`,
      pricelistId: pricelistId,
      emailProfiles: null
    }
  },
  methods: {
    isEmailDownloadEnabled() {
      return 1 === this.formBuilder.getField('download_type').value
    },
    isLinkDownloadEnabled() {
      return 2 === this.formBuilder.getField('download_type').value
    },
    isFTPDownloadEnabled() {
      return 3 === this.formBuilder.getField('download_type').value
    },
    isEmailProfilesExists() {
      return null !== this.emailProfiles && this.emailProfiles.length
    },
    async getPricelistDownloadConfig() {
      const successCallback = (response) => {
        if (response.data?.pricelist_download_config) {
          const downloadConfig = response.data.pricelist_download_config
          const isEmailConfig = 1 === downloadConfig?.type
          const isLinkConfig = 2 === downloadConfig?.type
          const isFtpConfig = 3 === downloadConfig?.type

          if (isEmailConfig) {
            this.formBuilder.updateFieldParam(
                'download_type',
                'value',
                1
            )
            this.formBuilder.updateFieldParam(
                'email_profile_id',
                'value',
                downloadConfig?.email_profile_id ?? 0 // 0 when email profile was removed and null here
            )
            this.formBuilder.updateFieldParam(
                'email_from',
                'value',
                downloadConfig?.email_from
            )
            this.formBuilder.updateFieldParam(
                'email_subject',
                'value',
                downloadConfig?.subject_config?.subject
            )
            this.formBuilder.updateFieldParam(
                'email_file_name',
                'value',
                downloadConfig?.file_config?.name
            )
          } else if (isLinkConfig || isFtpConfig) {
            this.formBuilder.updateFieldParam(
                'download_link',
                'value',
                downloadConfig?.download_link
            )

            if (isLinkConfig) {
              this.formBuilder.updateFieldParam(
                  'download_type',
                  'value',
                  2
              )
            } else {
              this.formBuilder.updateFieldParam(
                  'download_type',
                  'value',
                  3
              )
              this.formBuilder.updateFieldParam(
                  'ftp_login',
                  'value',
                  downloadConfig?.ftp_login
              )
              this.formBuilder.updateFieldParam(
                  'ftp_password',
                  'value',
                  downloadConfig?.ftp_password
              )
            }
          }
        }
      }

      await apiTransport({
        apiMethod: `pricelists/${this.pricelistId}/download-config`,
        successCallback: successCallback
      })
    },
    async getEmailProfiles() {
      const successCallback = (response) => {
        if (response.data?.email_profiles) {
          this.emailProfiles = response.data.email_profiles

          for (let key in this.emailProfiles) {
            let emailProfile = this.emailProfiles[key]

            this.formBuilder.addSelectOption('email_profile_id', {
              value: emailProfile.id,
              text: emailProfile.name
            })
          }
        }
      }

      await apiTransport({
        apiMethod: 'email-profiles',
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    // Fetch email profiles first important for mapping
    this.getEmailProfiles()
        .then(async () => {
          await this.getPricelistDownloadConfig()
        })
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>