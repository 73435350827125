import axios, {HttpStatusCode} from "axios";
import store from "@/store/store";
import router from "@/router/router";
import authSuccessCallback from "@/pages/PlatformAuth/authSuccessCallback";

const shot = async (params) => {
    const baseUrl = 'https://' + process.env.VUE_APP_API_URL
    const baseHeaders = {
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + store.getters.auth.token,
        'X-Refresh-Token': store.getters.auth.refresh_token
    }

    const requestParams = {
        url: baseUrl + params.apiMethod,
        headers: baseHeaders
    }

    if (params.payload) {
        requestParams.data = JSON.stringify(params.payload)
    }

    if (params.httpMethod) {
        requestParams.method = params.httpMethod
    }

    if (params.queryParams) {
        requestParams.params = params.queryParams
    }

    await axios(requestParams)
        .then((response) => {
            params.successCallback(response)

            pushNotification(response.data, 'success')
        })
        .catch((err) => {
            catchCallback(err, params)
        })
        .finally(() => {
            if (params?.finallyCallback) {
                params.finallyCallback()
            }
        })
}

const catchCallback = async (err, params) => {
    if (HttpStatusCode.Unauthorized === err.status) {
        if (params?.unauthorizedCallback) {
            params.unauthorizedCallback(err)
        } else if (params?.afterRefreshToken) {
            logoutCallback()
        } else {
            params.afterRefreshToken = true

            const newParams = { ...params } // clone params

            newParams.apiMethod = 'token/refresh'
            newParams.successCallback = (response) => {
                authSuccessCallback(response, async () => {
                    await shot(params)
                })
            }
            newParams.errorCallback = logoutCallback
            await shot(newParams)
        }
    } else if (HttpStatusCode.Forbidden === err.status) {
        pushNotification({
            message: 'Что-то пошло не так'
        }, 'error')
    } else {
        if (err.response?.data) {
            pushNotification(err.response?.data, 'error')
        }

        if (params.errorCallback) {
            params.errorCallback(err)
        } else {
            pushNotification({
                message: 'Что-то пошло не так',
                description: 'Попробуйте обновить страницу и повторить действие'
            }, 'error')
        }
    }
}

const logoutCallback = () => {
    store.commit('resetAuth')

    // Check for double sign in form redirect on async requests
    // It's important on "returnUrl" parameter exists (opposite side it will be added again recursive)
    // Other words, redirect one time only
    if ('sign_in' !== router.currentRoute.value.name) {
        router.push({
            name: 'sign_in',
            query: {
                returnUrl: router.currentRoute.value.fullPath
            }
        })
    }
}

const pushNotification = (data, type) => {
    if (!data?.message) {
        return
    }

    store.commit('addPageNotification', {
        label: data.message,
        description: data?.description,
        type: type
    })
}

export default async (params) => {
    await shot(params)
}
