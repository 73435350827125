<template>
  <div class="page-form">
    <h1>
      Платежная информация
    </h1>
    <div class="info-blocks">
      <div v-if="null !== activePeriodTill"
           class="info-block"
      >
        <div>
          <span class="grey-color">
            Оплаченный период истекает:
          </span> {{ activePeriodTill }}
        </div>
      </div>
    </div>
    <h2>
      История платежей
    </h2>
    <div class="info-blocks">
      <div v-for="payment in payments"
           class="info-block"
      >
        <h3>
          Платеж
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import apiTransport from "@/apitransport/apiTransport";

export default {
  data() {
    return {
      payments: [
        {}, {}, {}
      ],
      activePeriodTill: null
    }
  },
  methods: {
    async getBillingInfo() {
      const successCallback = (response) => {
        if (response.data?.billing_info) {
          this.activePeriodTill = response.data.billing_info.active_period_till
        }
      }

      await apiTransport({
        apiMethod: 'billing/info',
        successCallback: successCallback
      })
    }
  },
  beforeMount() {
    this.$emit('startLoading')
  },
  mounted() {
    this.getBillingInfo()
        .then(() => {
          this.$emit('finishLoading')
        })
  }
}
</script>